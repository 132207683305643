import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { unitStatuses } from '../../../config/commons';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { CAMPUS_URL, QUADRANT_DATA_URL } from '../../../config/config.api';

import { getFromDate, getToDate, SUCCESS_QUADRANT_PARAMETERS } from './helpers';
import { StockingQuadrantState, Unit, StockingQuadrant, AnalysisQuadrantFilter } from './interfaces';

const initialStateFilters = {
  fromDate: getFromDate(180),
  toDate: getToDate(),
  campusId: '',
  parameter: SUCCESS_QUADRANT_PARAMETERS.DENSITY,
};

const initialState: StockingQuadrantState = {
  isLoading: false,
  quadrantData: [],
  units: [],
  filters: initialStateFilters,
};

export const successQuadrantSlice = createSlice({
  name: 'successQuadrant',
  initialState,
  reducers: {
    setQuadrantData: (state: StockingQuadrantState, action: PayloadAction<StockingQuadrant[]>) => {
      state.quadrantData = action.payload;
    },
    setIsLoading: (state: StockingQuadrantState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUnits: (state: StockingQuadrantState, action: PayloadAction<Unit[]>) => {
      state.units = action.payload;
    },
    setFilters: (state: StockingQuadrantState, action: PayloadAction<AnalysisQuadrantFilter>) => {
      state.filters = action.payload;
    },
    resetFilters: (state: StockingQuadrantState) => {
      state.filters = initialStateFilters;
    },
  },
});

export const {
  setQuadrantData,
  setIsLoading,
  setUnits,
  setFilters,
  resetFilters,
} = successQuadrantSlice.actions;

export const fetchUnits = (params: { companyId: string, unitPhaseType: string }) => async (dispatch: Function) => {
  const { companyId, unitPhaseType } = params;
  const userSession = getUserSession();

  const unitParams = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType: unitPhaseType,
    active: true,
    status: [unitStatuses.ACTIVE],
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Unit[]>(CAMPUS_URL, { params: unitParams });
    dispatch(setUnits(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

const injectQuadrantType = (stockingQuadrant: StockingQuadrant[]) => {
  const survivalData = stockingQuadrant.map((item) => item.survivalRate);
  const weeklyGrowthData = stockingQuadrant.map((item) => item.weeklyGrowth);

  const survivalDataMin = Math.min(...survivalData);
  const survivalDataMax = Math.max(...survivalData);

  const weeklyGrowthDataMin = Math.min(...weeklyGrowthData);
  const weeklyGrowthDataMax = Math.max(...weeklyGrowthData);

  const averageSurvivalRate = (survivalDataMax + survivalDataMin) / 2;
  const averageWeeklyGrowth = (weeklyGrowthDataMax + weeklyGrowthDataMin) / 2;

  for (let index = 0; index < stockingQuadrant.length; index++) {
    const item = stockingQuadrant[index];

    if (item.survivalRate >= averageSurvivalRate) {
      stockingQuadrant[index].quadrant = item.weeklyGrowth >= averageWeeklyGrowth ? 1 : 2;
      continue;
    }

    stockingQuadrant[index].quadrant = item.weeklyGrowth >= averageWeeklyGrowth ? 3 : 4;
  }

  return stockingQuadrant;
};

export const fetchQuadrantData = (params: { companyId: string; campusId: string; phaseType: string; fromDate: string; toDate: string; hoursOffset: number }) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<StockingQuadrant[]>(QUADRANT_DATA_URL, { params });
    const stockingQuadrant = injectQuadrantType(response.data);
    dispatch(setQuadrantData(stockingQuadrant));
  } catch (error) {
    console.log(error?.response);
  }

  dispatch(setIsLoading(false));
};

export default successQuadrantSlice.reducer;
