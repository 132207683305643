import cx from 'classnames';
import moment from 'moment';
import { Row, Space, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { getHoursOffset } from '../../../utils/date';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { filterOptionSelect } from '../../../utils/select';
import { changeHeader } from '../../AppHeader/headerSlice';
import { handleResizeEvent } from '../../../utils/dimensions';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import CleanButton from '../../../common/components/buttons/CleanButton';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { THEME, stockingPhaseTypes, roundFourDecimals } from '../../../config/commons';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';

import styles from './SuccessQuadrant.module.scss';
import SuccessQuadrantD3 from './SuccessQuadrantD3';
import * as successQuadrantSlice from './successQuadrantSlice';
import { AnalysisQuadrantFilter, StockingQuadrant } from './interfaces';
import { getHeightOfTheOtherElements, getWidthOfTheOtherElements, disabledDateFrom, disabledDateTo, getXAxisMinMax, SUCCESS_QUADRANT_PARAMETERS, COLOR_LEGEND_WIDTH } from './helpers';

let chart: SuccessQuadrantD3 | null;

const { Option } = Select;

const SuccessQuadrant = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const refChartMain = useRef<HTMLDivElement>(null);
  const refFilters = useRef<HTMLDivElement>(null);
  const refTanksPanel = useRef<HTMLDivElement>(null);

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const {
    filters,
    isLoading,
    units,
    quadrantData,
  } = useSelector((state: Store) => state.successQuadrant);

  const [height, setHeight] = useState(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters }));
  const [width, setWidth] = useState(window.innerWidth - getWidthOfTheOtherElements({ tanksPanel: refTanksPanel }));

  const {
    fromDate,
    toDate,
    campusId,
    parameter,
  } = filters;

  useEffect(() => {
    dispatch(changeHeader({ title: t('quadrant.title') }));

    return () => {
      chart = null;
      dispatch(successQuadrantSlice.resetFilters());
      dispatch(successQuadrantSlice.setQuadrantData([]));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(successQuadrantSlice.resetFilters());
    dispatch(successQuadrantSlice.setQuadrantData([]));
  }, [phaseType, selectedCompany._id]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    const params = {
      companyId: selectedCompany._id,
      unitPhaseType: getUnitPhaseTypeFromAnalysis(phaseType),
    };

    dispatch(successQuadrantSlice.fetchUnits(params));
  }, [selectedCompany, phaseType]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements({ tanksPanel: refTanksPanel }));
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters }));
    });
  }, []);

  useEffect(() => {
    if (!selectedCompany._id || !quadrantData.length) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements({ tanksPanel: refTanksPanel }));
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters }));
  }, [selectedCompany._id, quadrantData]);

  useEffect(() => {
    if (!quadrantData.length || !selectedCompany?._id) {
      return;
    }

    const showChartMain = () => {
      const { max: lastStage, min: firstStage } = getXAxisMinMax({ quadrantData });
      const size = width > height ? height : width;

      if (chart) {
        chart.refreshChart({
          quadrantData,
          firstStage,
          height: size,
          width: size,
          lastStage,
          phaseType,
          parameter,
        });
        return;
      }

      chart = new SuccessQuadrantD3({
        container: refChartMain.current,
        quadrantData,
        firstStage,
        lastStage,
        height: size,
        width: size,
        phaseType,
        parameter,
      });
    };

    showChartMain();
  }, [quadrantData, chart, selectedCompany, width, height, phaseType, parameter]);

  useEffect(() => {
    const size = width > height ? height : width;
    chart && chart.resize({ height: size, width: size });
  }, [width, height]);

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_units'
        theme={theme}
        className={className}
        value={campusId || undefined}
        onChange={(value) => {
          const filterParams: AnalysisQuadrantFilter = {
            ...filters,
            campusId: value,
          };
          dispatch(successQuadrantSlice.setFilters(filterParams));

          const requestParams = {
            campusId: value,
            companyId: selectedCompany._id,
            fromDate: filterParams.fromDate,
            hoursOffset: getHoursOffset(),
            phaseType,
            toDate: filterParams.toDate,
          };
          dispatch(successQuadrantSlice.fetchQuadrantData(requestParams));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('quadrant.unit')}
        placeholder={t('quadrant.unit')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        {units.map((unit) => <Option key={unit._id} value={unit._id}>{unit.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderFromDate = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        theme={theme}
        className={className}
        id='from_date'
        title={t('quadrant.fromDate')}
        allowClear={false}
        disabledDate={(value) => disabledDateFrom(value, new Date(toDate))}
        value={fromDate === '' ? undefined : moment(fromDate)}
        placeholder={t('quadrant.fromDate')}
        onChange={(date, dateString) => {
          const filterParams: AnalysisQuadrantFilter = {
            ...filters,
            fromDate: dateString,
          };

          dispatch(successQuadrantSlice.setFilters(filterParams));

          const requestParams = {
            campusId: filterParams.campusId,
            companyId: selectedCompany._id,
            fromDate: dateString,
            hoursOffset: getHoursOffset(),
            phaseType,
            toDate: filterParams.toDate,
          };
          dispatch(successQuadrantSlice.fetchQuadrantData(requestParams));
        }}
      />
    );
  };

  const renderToDate = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        theme={theme}
        className={className}
        id='to_date'
        title={t('quadrant.toDate')}
        allowClear={false}
        disabledDate={(value) => disabledDateTo(value, new Date(fromDate))}
        value={fromDate === '' ? undefined : moment(toDate)}
        placeholder={t('quadrant.toDate')}
        onChange={(date, dateString) => {
          const filterParams: AnalysisQuadrantFilter = {
            ...filters,
            toDate: dateString,
          };

          dispatch(successQuadrantSlice.setFilters(filterParams));

          const requestParams = {
            campusId: filterParams.campusId,
            companyId: selectedCompany._id,
            fromDate: filterParams.fromDate,
            hoursOffset: getHoursOffset(),
            phaseType,
            toDate: dateString,
          };
          dispatch(successQuadrantSlice.fetchQuadrantData(requestParams));
        }}
      />
    );
  };

  const renderParameterDropdown = (props: DropdownProps) => {
    const { className, theme } = props;
    const title = t('quadrant.parameter');

    return (
      <LrvSelect
        id='dropdown_parameter'
        theme={theme}
        className={className}
        value={parameter || undefined}
        onChange={(value) => {
          const params: AnalysisQuadrantFilter = {
            ...filters,
            parameter: value,
          };

          dispatch(successQuadrantSlice.setFilters(params));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={title}
        placeholder={title}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        <Option key={SUCCESS_QUADRANT_PARAMETERS.DENSITY} value={SUCCESS_QUADRANT_PARAMETERS.DENSITY}>{t('quadrant.stockingDensity')}</Option>
        <Option key={SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME} value={SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME}>{t('quadrant.maturation')}</Option>
        <Option key={SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE} value={SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE}>{t('quadrant.maturationCode')}</Option>
        <Option key={SUCCESS_QUADRANT_PARAMETERS.MODULE} value={SUCCESS_QUADRANT_PARAMETERS.MODULE}>{t('quadrant.module')}</Option>
        {phaseType !== stockingPhaseTypes.LARVAE && <Option key={SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN} value={SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN}>{t('quadrant.previousOrigin')}</Option>}
      </LrvSelect>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (!campusId) {
      return (
        <div className={styles.center} >
          <LrvEmpty description={t('quadrant.emptyUnit')} theme={theme} />
        </div>
      );
    }

    if (!quadrantData.length) {
      return (
        <div className={styles.center} >
          <LrvEmpty description={t('quadrant.empty')} theme={theme} />
        </div>
      );
    }

    return null;
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon={true}
          title={<div className={styles.title}>{t('balances.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(successQuadrantSlice.resetFilters());
            },
          }}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderUnitsDropdown({ theme: 'light' })}
            {renderFromDate({ theme: 'light' })}
            {renderToDate({ theme: 'light' })}
            {renderParameterDropdown({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => {
          dispatch(successQuadrantSlice.resetFilters());
        }}
      />
    );
  };

  const getParameterLabel = () => {
    switch (parameter) {
      case SUCCESS_QUADRANT_PARAMETERS.DENSITY:
      default:
        return t('quadrant.stockingDensity');

      case SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME:
        return t('quadrant.maturation');

      case SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE:
        return t('quadrant.maturationCode');

      case SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN:
        return t('quadrant.previousOrigin');

      case SUCCESS_QUADRANT_PARAMETERS.CONTAINER:
        return t('quadrant.container');

      case SUCCESS_QUADRANT_PARAMETERS.MODULE:
        return t('quadrant.module');
    }
  };

  const renderTopAxis = () => {
    const svgWidth = (width > height ? height : width) + COLOR_LEGEND_WIDTH;
    const left = (width - svgWidth) / 2;

    return (
      <div
        className={styles.labelAxisY}
        style={{
          width: svgWidth,
          left: `${left}px`,
        }}
      >
        <LrvText
          className={isLightTheme ? styles.lightText : styles.darkText}
          text={t('quadrant.survivalRate') + ' %'}
        />

        <LrvText
          className={isLightTheme ? styles.lightText : styles.darkText}
          style={{
            width: COLOR_LEGEND_WIDTH + 11,
          }}
          text={getParameterLabel()}
        />
      </div>
    );
  };

  const renderXAxis = () => {
    const unitWeight = phaseType === stockingPhaseTypes.LARVAE ? t('quadrant.miligramPerDay') : t('quadrant.gramPerWeek');
    const label = phaseType === stockingPhaseTypes.LARVAE ? t('quadrant.dailyGrowth') : t('quadrant.weeklyGrowth');

    return (
      <div className={styles.labelAxisX}>
        <LrvText
          className={isLightTheme ? styles.lightText : styles.darkText}
          text={`${label} ${unitWeight}`}
          style={{
            right: `${COLOR_LEGEND_WIDTH / 2}px`,
          }}
        />
      </div>
    );
  };

  const getColumnsType = () => {
    const columnsType: ColumnsType<StockingQuadrant> = [
      {
        key: 1,
        width: '10%',
        title: t('quadrant.module'),
        dataIndex: 'moduleName',
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.moduleName.localeCompare(b.moduleName),
        className: styles.cell,
      },
      {
        key: 2,
        width: '7%',
        title: t('quadrant.container'),
        dataIndex: ['containerName'],
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.containerName.localeCompare(b.containerName),
        className: styles.cell,
      },
      {
        key: 3,
        width: '7%',
        title: t('quadrant.maturation'),
        dataIndex: ['maturationName'],
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.maturationName.localeCompare(b.maturationName),
        className: styles.cell,
      },
      {
        key: 4,
        width: '6%',
        title: t('quadrant.maturationCode'),
        dataIndex: ['maturationCode'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => (a.maturationCode || '').localeCompare(b.maturationCode || ''),
        className: styles.cell,
      },
      {
        key: 5,
        width: '10%',
        title: t('quadrant.survivalRate'),
        dataIndex: ['survivalRate'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.survivalRate - b.survivalRate,
        className: styles.cell,
        render: (_, record: StockingQuadrant) => roundFourDecimals(record.survivalRate),
      },
      {
        key: 6,
        width: '10%',
        title: t('quadrant.weeklyGrowth'),
        dataIndex: ['weeklyGrowth'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.weeklyGrowth - b.weeklyGrowth,
        className: styles.cell,
        render: (_, record: StockingQuadrant) => roundFourDecimals(record.weeklyGrowth),
      },
      {
        key: 7,
        width: '7%',
        title: t('quadrant.stockingDensity'),
        dataIndex: ['stockingDensity'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.stockingDensity - b.stockingDensity,
        className: styles.cell,
      },
      {
        key: 8,
        width: '8%',
        title: t('quadrant.uniformity'),
        dataIndex: ['uniformity'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.uniformity - b.uniformity,
        className: styles.cell,
      },
      {
        key: 9,
        width: '7%',
        title: t('quadrant.quadrant'),
        dataIndex: ['quadrant'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        sorter: (a, b) => a.quadrant - b.quadrant,
        className: styles.cell,
      },
    ];

    return columnsType;
  };

  const renderTable = () => {
    return (
      <LrvTable
        id='success-quadrant-table'
        className={cx(styles.table, isLightTheme ? styles.tableLight : styles.tableDark)}
        columns={getColumnsType()}
        loading={isLoading || !chart}
        dataSource={quadrantData}
        size='small'
        theme={theme}
        pagination={false}
        onRow={(record: StockingQuadrant) => {
          return {
            onClick: (e) => {
              e.stopPropagation();
              const url = `/production/stockings/${record.stockingId}`;
              window.open(url, '_blank');
            }
          };
        }}
      />
    );
  };

  return (
    <div className={styles.container}>
      <Row ref={refFilters} className={styles.filtersContainer}>
        <Space className={styles.filters} align='end'>
          {renderUnitsDropdown({ className: styles.select, theme })}
          {renderFromDate({ className: styles.select, theme })}
          {renderToDate({ className: styles.select, theme })}
          {renderParameterDropdown({ className: styles.select, theme })}
          {renderCleanButton()}
        </Space>
      </Row>

      {renderSidePanel()}
      {renderContent()}

      <div className={styles.containerSuccessQuadrant}>
        <div className={styles.successQuadrant} style={{ display: quadrantData.length > 0 ? '' : 'none' }}>
          {renderTopAxis()}

          <div className={styles.containerChart}>
            <div className={styles.chart}>
              <div id='chart' ref={refChartMain} />
            </div>
          </div>
          {renderXAxis()}

          {renderTable()}
        </div>
      </div>
    </div>
  );
};

export default SuccessQuadrant;