/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';

import { roundOneDecimal, THEME, typeHistogram } from '../../../config/commons';

import { Dataset } from './interfaces';
import styles from './Histogram.module.scss';
import { colosPigmentation, typeStyles } from './histogram.helpers';


export default class HistogramD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));

  x: d3.ScaleBand<string> = d3.scaleBand();
  y: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  xScaleLinear: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

  bars: d3.Selection<SVGRectElement, Dataset, null, undefined> = d3.select<SVGRectElement, Dataset>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  textLimits: d3.Selection<SVGTextElement, number, null, unknown> = d3.select<SVGTextElement, number>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));;
  textFrequencies: d3.Selection<SVGTextElement, Dataset, null, unknown> = d3.select<SVGTextElement, Dataset>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));;

  timeTransition = 300;

  width = 0;
  height = 0;
  margin = { top: 35, right: 12, bottom: 25, left: 12 };

  dataset: Dataset[];
  histogram: string;
  limits: number[];
  average: number;
  unit?: string;

  //styles
  typeStyles = '';
  averageTextStyle = '';
  limitsStyle = '';
  frequenciesStyles = '';
  xAxisPadding = 0.01;
  fillBars = '';
  theme?: 'dark' | 'light' = 'dark';

  // eslint-disable-next-line
  constructor(
    container: HTMLDivElement | null,
    dataset: Dataset[],
    histogram: string,
    limits: number[],
    average: number,
    width: number,
    height: number,
    typeStyles: string,
    theme: 'dark' | 'light',
    unit?: string,
  ) {
    this.container = container;
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.dataset = dataset;
    this.histogram = histogram;
    this.limits = limits;
    this.average = average;
    this.unit = unit;

    this.typeStyles = typeStyles;
    this.theme = theme;

    this.updateStyles();
    this.createSvgElement(this.width, this.height);
    this.createHistogram(dataset, limits, average);
  }

  updateStyles () {
    const isLightTheme = this.theme === THEME.LIGHT;

    if (this.typeStyles === typeStyles.DEFAULT) {
      this.xAxisPadding = 0.01;
      this.fillBars = isLightTheme ? '#1b62e6' : '#0000ff';
      this.averageTextStyle = styles.averageTextDefault;
      this.limitsStyle = isLightTheme ? styles.limitsDefaultLight : styles.limitsDefaultDark;
      this.frequenciesStyles = isLightTheme ? styles.frequenciesDefaultLight : styles.frequenciesDefaultDark;
    } else if (this.typeStyles === typeStyles.PDF) {
      this.xAxisPadding = 0.05;
      this.fillBars = '#2668AD';
      this.averageTextStyle = styles.averageTextPdf;
      this.limitsStyle = styles.limitsPdf;
      this.frequenciesStyles = styles.frequenciesPdf;
    }
  }

  createSvgElement (width: number, height: number) {
    const { container, margin } = this;
    d3.select(container).select('svg').remove();

    this.svg = d3.select(container)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('id', 'content')
      .append('g')
      .attr('transform', `translate( ${margin.left} , ${margin.top} )`);
  }

  getFrecuencyPositionX (i: number) {
    return (i * this.x.bandwidth()) + (this.x.bandwidth() / 2) + (i * this.x.bandwidth() * this.xAxisPadding);
  }

  getFrecuencyLabel = (d: Dataset, i: number) => {
    const { dataset } = this;

    if (dataset.length >= 7) {
      if (d.percent) {
        return `<tspan dy='0em' x='${this.getFrecuencyPositionX(i)}'>${d.frequency}</tspan><tspan dy='1.0em' x='${this.getFrecuencyPositionX(i)}'>${roundOneDecimal(d.percent)}%</tspan>`;
      }
      return `${d.frequency}`;
    }

    if (d.percent) {
      return `${d.frequency} - ${roundOneDecimal(d.percent)}%`;
    }
    return `${d.frequency}`;
  }

  getFrecuencyPositionY = (d: Dataset) => {
    const { dataset } = this;

    if (dataset.length >= 7) {
      return this.y(d.frequency) - 15;
    }

    return this.y(d.frequency) - 5;
  }

  createHistogram (dataset: Dataset[], limits: number[], average: number) {
    const { svg, height } = this;

    this.buildAxisX();
    this.buildAxisY();

    // eslint-disable-next-line
    // @ts-ignore
    this.bars = svg.selectAll('.histogram')
      .data(dataset)
      .enter()
      .append('rect')
      .attr('class', 'histogram')
      .attr('x', (d: Dataset) => this.x(d.index.toString()) as number)
      .attr('width', this.x.bandwidth())
      .attr('y', (d: Dataset) => this.y(d.frequency))
      .attr('height', (d: Dataset) => height - this.y(d.frequency))
      .style('fill', (d: Dataset, index: number) => {
        if (this.histogram === typeHistogram.PIGMENTATION) {
          return colosPigmentation[index];
        }
        return this.fillBars;
      });

    const positionY = this.typeStyles === typeStyles.DEFAULT ? 16 : 13;
    // eslint-disable-next-line
    // @ts-ignore
    this.textLimits = svg.selectAll('.limits')
      .data(limits)
      .enter()
      .append('text')
      .attr('class', cx('limits', this.limitsStyle))
      .attr('x', (_, i: number) => (i * this.x.bandwidth()) + (i * this.x.bandwidth() * this.xAxisPadding))
      .attr('y', height + positionY)
      .style('text-anchor', 'middle')
      .text((d: number) => d);

    // eslint-disable-next-line
    // @ts-ignore
    this.textFrequencies = svg.selectAll('.frequencies')
      .data(dataset)
      .enter()
      .append('text')
      .attr('class', cx('frequencies', this.frequenciesStyles))
      .attr('x', (d: Dataset, i: number) => {
        return this.getFrecuencyPositionX(i);
      })
      .attr('y', (d: Dataset) => this.getFrecuencyPositionY(d))
      .style('text-anchor', 'middle')
      .html((d: Dataset, i: number) => this.getFrecuencyLabel(d, i));

    if (this.histogram !== typeHistogram.PIGMENTATION && this.histogram !== typeHistogram.SURVIVAL_RATE) {
      svg.append('line')
        .attr('class', cx('averageLine', styles.averageLine))
        .attr('x1', this.xScaleLinear(average))
        .attr('y1', -20)
        .attr('x2', this.xScaleLinear(average))
        .attr('y2', height);

      svg.append('text')
        .attr('class', cx('averageText', this.averageTextStyle))
        .attr('x', this.xScaleLinear(average))
        .attr('y', -25)
        .style('text-anchor', 'middle')
        .text(`${i18next.t('detail.avg')} ${average} ${this.unit}`);
    }
  }

  refreshHistogram (dataset: Dataset[], histogram: string, limits: number[], average: number, theme: 'dark' | 'light', unit?: string) {
    const { container, svg, height, timeTransition } = this;

    this.dataset = dataset;
    this.histogram = histogram;
    this.limits = limits;
    this.average = average;
    this.theme = theme;
    this.unit = unit;

    this.updateStyles();
    this.buildAxisX();
    this.buildAxisY();

    // eslint-disable-next-line
    // @ts-ignore
    this.bars = svg.selectAll('.histogram')
      .data(dataset);

    this.bars.enter()
      .append('rect')
      .merge(this.bars)
      .transition()
      .duration(timeTransition)
      .attr('class', 'histogram')
      .attr('x', (d: Dataset) => this.x(d.index?.toString()) as number)
      .attr('width', this.x.bandwidth())
      .attr('y', (d: Dataset) => this.y(d.frequency))
      .attr('height', (d: Dataset) => height - this.y(d.frequency))
      .style('fill', (d: Dataset, index: number) => {
        if (this.histogram === typeHistogram.PIGMENTATION) {
          return colosPigmentation[index];
        }
        return this.fillBars;
      });

    this.bars.exit()
      .remove();

    const positionY = this.typeStyles === typeStyles.DEFAULT ? 16 : 12;

    //update limits
    // eslint-disable-next-line
    // @ts-ignore
    this.textLimits = svg.selectAll('.limits')
      .data(limits);

    this.textLimits.enter()
      .append('text')
      .merge(this.textLimits)
      .transition()
      .duration(timeTransition)
      .attr('class', cx('limits', this.limitsStyle))
      .attr('x', (d: number, i: number) => (i * this.x.bandwidth()) + (i * this.x.bandwidth() * this.xAxisPadding))
      .attr('y', height + positionY)
      .style('text-anchor', 'middle')
      .text((d: number) => d);

    this.textLimits.exit()
      .remove();

    //update frequency
    // eslint-disable-next-line
    // @ts-ignore
    this.textFrequencies = svg.selectAll('.frequencies')
      .data(dataset);

    this.textFrequencies.enter()
      .append('text')
      .merge(this.textFrequencies)
      .attr('class', cx('frequencies', this.frequenciesStyles))
      .attr('x', (d: Dataset, i: number) => this.getFrecuencyPositionX(i))
      .attr('y', (d: Dataset) => this.getFrecuencyPositionY(d))
      .style('text-anchor', 'middle')
      .html((d: Dataset, i: number) => this.getFrecuencyLabel(d, i));

    this.textFrequencies.exit()
      .remove();

    d3.select(container).selectAll('.averageLine').remove();
    d3.select(container).selectAll('.averageText').remove();

    if (this.histogram !== typeHistogram.PIGMENTATION && this.histogram !== typeHistogram.SURVIVAL_RATE) {
      svg.append('line')
        .attr('class', cx('averageLine', styles.averageLine))
        .attr('x1', this.xScaleLinear(average))
        .attr('y1', -20)
        .attr('x2', this.xScaleLinear(average))
        .attr('y2', height);

      svg.append('text')
        .attr('class', cx('averageText', this.averageTextStyle))
        .attr('x', this.xScaleLinear(average))
        .attr('y', -25)
        .style('text-anchor', 'middle')
        .text(`${i18next.t('detail.avg')} ${average} ${this.unit}`);
    }
  }

  buildAxisX () {
    const { dataset, limits, width } = this;

    this.x = d3.scaleBand()
      .domain(dataset.map(function (d) { return d.index.toString(); }))
      .range([0, width])
      .padding(this.xAxisPadding);

    this.xScaleLinear = d3.scaleLinear()
      .domain([limits[0], limits[limits.length - 1]])
      .range([0, width]);
  }

  buildAxisY () {
    const { dataset, height } = this;
    let maxDomain: number = d3.max(dataset, function (d) { return d.frequency; }) ?? 0;
    maxDomain = maxDomain <= 0 ? 10 : maxDomain;

    this.y = d3
      .scaleLinear()
      .domain([0, maxDomain])
      .range([height, 0]);
  }

  resize (width: number, height: number) {
    const { container, dataset, limits, average } = this;
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('#content').selectAll('*').remove();

    this.createSvgElement(this.width, this.height);
    this.createHistogram(dataset, limits, average);
  }
}

