import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { unitStatuses } from '../../../config/commons';
import { getUserSession } from '../../../utils/userSession';
import { dateRangeOptions } from '../../Sowings/Parameters/helpers';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { typeParam } from '../../../common/components/charts/ShadedPlot/helpers';
import { CAMPUS_URL, MODULES_URL, STOCKING_PARAMETER_GRAPH_URL, REFERENCE_CURVES_URL, REFERENCE_CURVES_BY_FILTERS_URL } from '../../../config/config.api';

import { ParameterGraphState, Unit, Module, StockingParameterGraph, ParameterTypes, ReferenceCurve } from './interfaces';

const initialState: ParameterGraphState = {
  isLoading: false,
  selectedDateOption: dateRangeOptions.WHOLE_CROP.value,
  selectedParameterName: 'averageWeight',
  modules: [],
  units: [],
  stockingParameterGraphs: [],
  companyReferenceCurves: [],
  globalReferenceCurves: [],
  unitReferenceCurves: [],
};

export const parameterStateSlice = createSlice({
  name: 'parameterState',
  initialState,
  reducers: {
    setStockingParameterGraphs: (state: ParameterGraphState, action: PayloadAction<StockingParameterGraph[]>) => {
      state.stockingParameterGraphs = action.payload;
    },
    setIsLoading: (state: ParameterGraphState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUnits: (state: ParameterGraphState, action: PayloadAction<Unit[]>) => {
      state.units = action.payload;
    },
    setSelectedUnit: (state: ParameterGraphState, action: PayloadAction<Unit>) => {
      state.selectedUnit = action.payload;
    },
    setModules: (state: ParameterGraphState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setSelectedModule: (state: ParameterGraphState, action: PayloadAction<Module>) => {
      state.selectedModule = action.payload;
    },
    setSelectedUnitId: (state: ParameterGraphState, action: PayloadAction<string | undefined>) => {
      state.unitId = action.payload;
    },
    setSelectedModuleId: (state: ParameterGraphState, action: PayloadAction<string | undefined>) => {
      state.moduleId = action.payload;
    },
    setSelectedReferenceCurve: (state: ParameterGraphState, action: PayloadAction<ReferenceCurve | undefined>) => {
      state.selectedReferenceCurve = action.payload;
    },
    setSelectedDateOption: (state: ParameterGraphState, action: PayloadAction<string>) => {
      state.selectedDateOption = action.payload;
    },
    setSelectedParameterName: (state: ParameterGraphState, action: PayloadAction<ParameterTypes>) => {
      state.selectedParameterName = action.payload;
    },
    resetFilters: (state: ParameterGraphState) => {
      state.unitId = undefined;
      state.moduleId = undefined;
      state.selectedReferenceCurve = undefined;
      state.modules = [];
      state.stockingParameterGraphs = [];
      state.selectedDateOption = dateRangeOptions.WHOLE_CROP.value;
      state.selectedParameterName = 'averageWeight';
    },
    setGlobalReferenceCurves: (state: ParameterGraphState, action: PayloadAction<ReferenceCurve[]>) => {
      state.globalReferenceCurves = action.payload;

      if (state.globalReferenceCurves.length <= 0) {
        return;
      }

      const globalReferenceCurve = state.globalReferenceCurves[0];
      state.selectedReferenceCurve = globalReferenceCurve;
    },
    setCompanyReferenceCurves: (state: ParameterGraphState, action: PayloadAction<ReferenceCurve[]>) => {
      state.companyReferenceCurves = action.payload;
    },
    setUnitReferenceCurves: (state: ParameterGraphState, action: PayloadAction<ReferenceCurve[]>) => {
      state.unitReferenceCurves = action.payload;
    },
  },
});

export const {
  setStockingParameterGraphs,
  setIsLoading,
  setUnits,
  setSelectedUnit,
  setModules,
  setSelectedModule,
  setSelectedUnitId,
  setSelectedModuleId,
  setSelectedReferenceCurve,
  setSelectedDateOption,
  setSelectedParameterName,
  resetFilters,
  setGlobalReferenceCurves,
  setCompanyReferenceCurves,
  setUnitReferenceCurves,
} = parameterStateSlice.actions;

export const fetchStockingParameterGraphs = (props: { companyId: string; campusId: string; moduleId: string; phaseType: string; accessToken?: string }) => async (dispatch: Function) => {
  const { companyId, campusId, moduleId, phaseType, accessToken } = props;

  dispatch(setStockingParameterGraphs([]));
  dispatch(setIsLoading(true));

  const params = {
    companyId,
    campusId,
    moduleId,
    phaseType,
  };

  try {
    if (accessToken) {
      const response = await axios.get<StockingParameterGraph[]>(STOCKING_PARAMETER_GRAPH_URL, {
        headers: { 'Authorization': accessToken },
        params,
      });

      dispatch(setStockingParameterGraphs(response.data));
      dispatch(setIsLoading(false));
      return;
    }

    const response = await axios.get<StockingParameterGraph[]>(STOCKING_PARAMETER_GRAPH_URL, { params });
    dispatch(setStockingParameterGraphs(response.data));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchUnits = (params: { companyId: string, unitPhaseType: string }) => async (dispatch: Function) => {
  const { companyId, unitPhaseType } = params;
  const userSession = getUserSession();

  const unitParams = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType: unitPhaseType,
    active: true,
    status: [unitStatuses.ACTIVE],
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Unit[]>(CAMPUS_URL, { params: unitParams });
    dispatch(setUnits(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchModules = (props: { campusId: string, phaseType: string }) => async (dispatch: Function) => {
  const { campusId, phaseType } = props;

  const params = {
    $limit: -1,
    campusId,
    phaseType,
    active: true,
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params });
    dispatch(setModules(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchGlobalReferenceCurves = (props: { phaseType: string; type?: string }) => async (dispatch: Function) => {
  const { phaseType, type = typeParam.AVG_WEIGHT } = props;

  const params = {
    $limit: -1,
    phaseType,
    type,
    'companyId[$exists]': false,
    active: true,
  };

  try {
    const response = await axios.get<ReferenceCurve[]>(REFERENCE_CURVES_URL, { params });
    dispatch(setGlobalReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchCompanyReferenceCurves = (props: { companyId: string; phaseType: string; type?: string }) => async (dispatch: Function) => {
  const params = {
    ...props,
    type: typeParam.AVG_WEIGHT,
    active: true,
  };

  try {
    const response = await axios.get<ReferenceCurve[]>(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setCompanyReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchUnitsReferenceCurves = (props: { companyId: string; campusId: string; phaseType: string; type?: string }) => async (dispatch: Function) => {
  const params = {
    ...props,
    type: typeParam.AVG_WEIGHT,
    active: true,
  };

  try {
    const response = await axios.get<ReferenceCurve[]>(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setUnitReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchUnit = (params: { unitId: string; accessToken?: string; }) => async (dispatch: Function) => {
  const { accessToken, unitId } = params;

  const url = `${CAMPUS_URL}/${unitId}`;

  try {
    if (accessToken) {
      const response = await axios.get<Unit>(url, {
        headers: { 'Authorization': accessToken },
      });

      dispatch(setSelectedUnit(response.data));
      return;
    }

    const response = await axios.get<Unit>(url);
    dispatch(setSelectedUnit(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchModule = (params: { moduleId: string; accessToken?: string; }) => async (dispatch: Function) => {
  const { accessToken, moduleId } = params;

  const url = `${MODULES_URL}/${moduleId}`;

  try {
    if (accessToken) {
      const response = await axios.get<Module>(url, {
        headers: { 'Authorization': accessToken },
      });

      dispatch(setSelectedModule(response.data));
      return;
    }

    const response = await axios.get<Module>(url);
    dispatch(setSelectedModule(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchReferenceCurve = (props: { referenceId: string; accessToken?: string; }) => async (dispatch: Function) => {
  const { accessToken, referenceId } = props;
  const url = `${REFERENCE_CURVES_URL}/${referenceId}`;

  try {
    if (accessToken) {
      const response = await axios.get<ReferenceCurve>(url, {
        headers: { 'Authorization': accessToken },
      });

      dispatch(setSelectedReferenceCurve(response.data));
      return;
    }

    const response = await axios.get<ReferenceCurve>(url);
    dispatch(setSelectedReferenceCurve(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export default parameterStateSlice.reducer;
