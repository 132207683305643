import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Form, Select, Row, Col, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { sortTankStatus } from '../../utils/sort';
import { useDragger } from '../../hooks/useDragger';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { getUserSession } from '../../utils/userSession';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { getContainerVolumeLimits } from '../../helpers/tanks.helpers';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvDragger } from '../../common/components/LrvDragger/LrvDragger';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { typeParam } from '../../common/components/charts/ShadedPlot/helpers';
import { LrvCheckbox } from '../../common/components/LrvCheckbox/LrvCheckbox';
import { openErrorNotification } from '../../common/notification/Notification';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { isFieldsTouchedCustom, isNumber, validateNumber } from '../../utils/validations';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';
import { disabledDateJuvenile, getUnitPhaseTypeFromStocking } from '../../helpers/stocking.helpers';
import { ProductionCycleInfo } from '../../common/components/ProductionCycleInfo/ProductionCycleInfo';
import { tankStatuses, typeFetchModule, typeFetchTank, stockingCodeLength, stockingPhaseTypes, containerTypes, THEME, maximumNumberOfStockings } from '../../config/commons';

import './Sowings.scss';
import styles from './Sowings.module.scss';
import * as stockingSlice from './sowingsSlice';
import { BindingStocking, Campus, Container, StockingJuvenile } from './interfaces';
import { BindingStockingTable, NewBindingStockingButton, ErrorLabelRow } from './BindingStocking';
import { defaultContainerData, getBindingStockingByMaxAnimals, getBindingStockingData, getTotalAnimals, initialBindingStocking, isValidStockingBinding, isValidStockingBindingRow, stockingBindingRowIsFull } from './sowings.helpers';

const { Option } = Select;

interface Props {
  theme?: 'dark' | 'light';
}

export const NewStockingJuvenile = (props: Props) => {
  const { theme = 'dark' } = props;
  const [formNewStockingJuvenile] = Form.useForm();

  const {
    canvasRef,
    handleCustomRequest,
    handleOnChange,
    imageUrl,
    loadingImage,
    reducedImage,
    voucherHeight,
    voucherWidth,
    cleanImage,
  } = useDragger({ applyBlackWhite: true });

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    limit, skip,
    selectedCampus,
    selectedModuleId: moduleId,
    selectedTankId: tankId,
    showCreateModalJuvenile,
    campusesForm,
    modulesForm,
    tanksForm,
    maturations,
    maturationCodes,
    stockingDateRanges,
    referenceCurves,
    filters
  } = useSelector((state: Store) => state.stockings);

  const {
    stockingsToShow
  } = filters;

  const tanks = cloneDeep(tanksForm);
  tanks.sort(sortTankStatus);

  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const currentPage = ((skip / limit) + 1) || 1;
  const volumeRanges = useSelector((state: Store) => state.campus.volumeRanges);
  const { company, phaseType } = useSelector((state: Store) => state.header);

  const [disabledButtonFormNewStocking, setDisabledButtonFormNewStocking] = useState(true);

  const [newStockingNumberJuvenile, setNewStockingNumberJuvenile] = useState<number | string>(0);
  const [newStockingCodeJuvenile, setNewStockingCodeJuvenile] = useState('');
  const [newStockingNameJuvenile, setNewStockingNameJuvenile] = useState('');
  const [newStockingStartDateJuvenile, setNewStockingStartDateJuvenile] = useState('');
  const [newStockingMaturationJuvenile, setNewStockingMaturationJuvenile] = useState('');
  const [newStockingMaturationCodeJuvenile, setNewStockingMaturationCodeJuvenile] = useState('');
  const [newStockingCampusJuvenile, setNewStockingCampusJuvenile] = useState('');
  const [newStockingModuleJuvenile, setNewStockingModuleJuvenile] = useState('');
  const [newStockingTankJuvenile, setNewStockingTankJuvenile] = useState('');
  const [newStockingCubicMetersJuvenile, setNewStockingCubicMetersJuvenile] = useState<number | string>(0);
  const [newStockingLabNameJuvenile, setNewStockingLabNameJuvenile] = useState('');
  const [newStockingParameter, setNewStockingParameter] = useState<string | undefined>(undefined);
  const [newStockingReference, setNewStockingReference] = useState<string | undefined>(undefined);
  const [newBindingStockingsJuvenile, setNewBindingStockingsJuvenile] = useState<BindingStocking[]>([initialBindingStocking]);
  const [hasBindingStockingJuvenile, setHasBindingStockingJuvenile] = useState<boolean>(false);

  const [campusListForStockingCreation, setCampusListForStockingCreation] = useState<Campus[]>([]);
  const [containerSelected, setContainerSelected] = useState<Container>(defaultContainerData);

  const userSession = getUserSession();
  const containerVolumeLimits = getContainerVolumeLimits({ volumeRanges, phaseType });

  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    if (!showCreateModalJuvenile) {
      return;
    }

    const currentTab = getUnitPhaseTypeFromStocking(phaseType);
    const campusesList = campusesForm.filter((campus) => campus.phaseType === currentTab);
    setCampusListForStockingCreation(campusesList);
  }, [campusesForm, showCreateModalJuvenile]);

  useEffect(() => {
    if (!showCreateModalJuvenile) {
      return;
    }

    if (containerSelected.volume !== undefined && containerSelected.volume !== 0) {
      formNewStockingJuvenile.setFieldsValue({
        measureUnitJuvenile: containerSelected.volume
      });
      setNewStockingCubicMetersJuvenile(containerSelected.volume);

      return;
    }

    if (containerSelected.type !== '') {
      formNewStockingJuvenile.setFieldsValue({
        measureUnitJuvenile: containerVolumeLimits[containerSelected.type].min
      });
      setNewStockingCubicMetersJuvenile(containerVolumeLimits[containerSelected.type].min);
    }

    // for containerVolumeLimits
    // eslint-disable-next-line
  }, [formNewStockingJuvenile, containerSelected._id, containerSelected.type, containerSelected.volume, showCreateModalJuvenile]);
  //#endregion

  //#region Commons functions
  function onChangeStockingCampusJuvenile (value: string) {
    formNewStockingJuvenile.setFieldsValue({
      campusIdJuvenile: value,
      moduleIdJuvenile: undefined,
      tankIdJuvenile: undefined,
      measureUnitJuvenile: undefined,
    });

    setNewStockingCubicMetersJuvenile(0);
    setNewStockingCampusJuvenile(value);
    setNewStockingModuleJuvenile('');
    setNewStockingTankJuvenile('');

    setContainerSelected(defaultContainerData);
    dispatch(stockingSlice.fetchModules(value, typeFetchModule.FORM));
  }

  function onChangeStockingModuleJuvenile (value: string) {
    formNewStockingJuvenile.setFieldsValue({
      moduleId: value,
      tankId: undefined,
      measureUnitJuvenile: undefined,
    });

    setNewStockingCubicMetersJuvenile(0);
    setNewStockingModuleJuvenile(value);
    setNewStockingTankJuvenile('');

    setContainerSelected(defaultContainerData);
    dispatch(stockingSlice.fetchTanks(newStockingCampusJuvenile, value, typeFetchTank.FORM));
  }

  function onChangeMaturationJuvenile (value: string) {
    formNewStockingJuvenile.setFieldsValue({
      maturationCode: undefined,
    });

    setNewStockingMaturationJuvenile(value);
    setNewStockingMaturationCodeJuvenile('');
    dispatch(stockingSlice.fetchMaturationCodes(value));
  }

  function onChangeStockingTankJuvenile (value: string) {
    setNewStockingTankJuvenile(value);

    const tank = tanks.find((tank) => tank._id === value);
    if (tank) {
      setContainerSelected(tank);
    }
  }
  //#endregion

  const closeModal = () => {
    formNewStockingJuvenile.resetFields();

    dispatch(stockingSlice.setShowCreateModalJuvenile(false));
    setNewStockingCampusJuvenile('');
    setNewStockingModuleJuvenile('');
    setNewStockingTankJuvenile('');
    setNewStockingMaturationJuvenile('');
    setNewStockingLabNameJuvenile('');
    cleanImage();

    setHasBindingStockingJuvenile(false);
    setNewBindingStockingsJuvenile([initialBindingStocking]);
    setContainerSelected(defaultContainerData);
  };

  const selectedJuvenileContainerType = (tanks.find((tank) => tank._id === newStockingTankJuvenile))?.type;

  const renderDraggerFile = () => {
    return (
      <div>
        <p>
          <InboxOutlined />
        </p>
        <p>{t('payments.drag')}</p>
      </div>
    );
  };

  const renderBindingStocking = () => {
    return (
      <Form.Item>
        <LrvCheckbox
          theme='light'
          checked={hasBindingStockingJuvenile}
          onChange={event => {
            setHasBindingStockingJuvenile(event.target.checked);
          }}
        >
          {t('stockings.binding')}
        </LrvCheckbox>
      </Form.Item>
    );
  };

  const validateFormJuvenile = () => {
    const formFields = Object.keys(formNewStockingJuvenile.getFieldsValue());

    const fieldsNotRequired = ['invoiceImg', 'labName', 'maturationCode', 'bindingCodes', 'parameter', 'referenceCurveId'];
    if (hasBindingStockingJuvenile) {
      fieldsNotRequired.push('maturation');
      fieldsNotRequired.push('numberJuvenile');
    }

    const validFormFields = formFields.filter((element) => !fieldsNotRequired.includes(element));
    validateCreateStockingForm(validFormFields);
  };

  const getDataStockingJuvenile = async () => {
    const newStocking: StockingJuvenile = {
      startDateJuvenile: newStockingStartDateJuvenile,
      juvenilesNumber: Number(newStockingNumberJuvenile),
      campusId: newStockingCampusJuvenile,
      tankId: newStockingTankJuvenile,
      name: newStockingNameJuvenile,
      code: newStockingCodeJuvenile,
      userId: userSession._id,
      maturationId: newStockingMaturationJuvenile,
      invoiceImg: reducedImage,
      phaseType: stockingPhaseTypes.JUVENILE,
      referenceCurveId: newStockingReference,
    };

    if (newStockingLabNameJuvenile) {
      newStocking.labName = newStockingLabNameJuvenile;
    }

    if (selectedJuvenileContainerType === containerTypes.RACEWAY) {
      newStocking.cubicMeters = Number(newStockingCubicMetersJuvenile);
    } else if (selectedJuvenileContainerType === containerTypes.PRE_HATCHERY) {
      newStocking.hectares = Number(newStockingCubicMetersJuvenile);
    }

    if (newStockingMaturationCodeJuvenile) {
      newStocking.maturationCode = newStockingMaturationCodeJuvenile;
    }

    if (hasBindingStockingJuvenile) {
      const bindingStockings: BindingStocking[] = cloneDeep(newBindingStockingsJuvenile);
      for (let index = 0; index < bindingStockings.length; index++) {
        const newBindingStocking = bindingStockings[index];

        if (isValidStockingBindingRow(newBindingStocking)) {
          const itemBindingStocking = await stockingSlice.fetchStockingByBindingCode({ code: newBindingStocking.bindingCode, index, bindingStockings });
          bindingStockings[index] = itemBindingStocking;

          validateFormJuvenile();
        }
      }

      setNewBindingStockingsJuvenile(bindingStockings);
      const isValid = isValidStockingBinding(bindingStockings);
      if (!isValid) {
        openErrorNotification(t('stockings.invalidBindingStocking'));
        return;
      }

      const animales = getTotalAnimals(bindingStockings);
      const bindingStocking = getBindingStockingByMaxAnimals(bindingStockings);

      newStocking.bindingStockings = bindingStockings;
      newStocking.maturationId = bindingStocking.maturationId;
      newStocking.maturationCode = bindingStocking.maturationCode;
      newStocking.labId = bindingStocking.labId;
      newStocking.labName = bindingStocking.labName;
      newStocking.juvenilesNumber = animales;
    }

    return newStocking;
  };

  const createStocking = async () => {
    const newStocking: StockingJuvenile | undefined = await getDataStockingJuvenile();
    if (newStocking) {
      const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId, tankId, page: currentPage, stockingsToShow, phaseType: phaseType };
      dispatch(stockingSlice.createStocking({ stockingData: newStocking, paramsToFetchStocking, closeModal }));
    }

    if (isRunningOnboarding) {
      dispatch(goToOnboardingNextStep(2000));
    }
  };

  const renderBindingCodeWarning = () => {
    if (!hasBindingStockingJuvenile) {
      return null;
    }

    return (
      <Form.Item>
        <TinyLabel className={styles.warning} text={t('stockings.bindingCodeWarning')} />
      </Form.Item>
    );
  };

  const renderBindingStockingRows = () => {
    const columns: ColumnsType<BindingStocking> = [
      {
        title: t('stockings.bindingCode'),
        dataIndex: 'bindingCode',
        width: '45%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <LrvInput
                theme='light'
                value={record.bindingCode}
                className={styles.input}
                maxLength={5}
                onChange={(e) => {
                  if (e.target.value == null || e.target.value == undefined) {
                    return;
                  }

                  onChangeBindingCodeJuvenile(record, e.target.value);
                }}
              />

              <ErrorLabelRow hasError={record.validatedCode && !record.isValid} />
            </>
          );
        },
      },
      {
        title: t('stockings.sownAnimals'),
        dataIndex: 'animals',
        width: '45%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <LrvInputNumber
                theme='light'
                value={record.animals}
                className={styles.input}
                min={0}
                formatter={value => applyThousandsSeparator(value)}
                parser={value => applyParserThousandsSeparator(value)}
                onChange={(value) => {
                  if (value == null || value == undefined || !isNumber(value)) {
                    return;
                  }

                  onChangeAnimalsJuvenile(record, value.toString());
                }}
              />
              <ErrorLabelRow />
            </>
          );
        },
      },
      {
        title: '',
        dataIndex: 'remove',
        width: '10%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <Space className={styles.bindingCode} size='small'>
                <LrvTooltip
                  themeStyle={isLightTheme ? 'dark' : 'light'}
                  title={t('stockings.delete')}
                >
                  <ActionButton
                    type='text'
                    icon={<Icon name='delete-bin' type='line' theme={theme} />}
                    onClick={() => {
                      onDeleteJuvenileRow(record.key);
                    }}
                  />
                </LrvTooltip>
              </Space>
              <ErrorLabelRow />
            </>
          );
        },
      },
    ];

    return (
      <BindingStockingTable columns={columns} dataSource={getDataBindingStocking()} />
    );
  };

  const validateCreateStockingForm = (validFormFields: string[]) => {
    setDisabledButtonFormNewStocking(
      !isFieldsTouchedCustom(formNewStockingJuvenile, validFormFields) ||
      formNewStockingJuvenile.getFieldsError().filter(({ errors }) => errors.length).length > 0
    );
  };

  const onChangeBindingCodeJuvenile = (record: BindingStocking, bindingCode: string) => {
    const bindingStockingCopy: BindingStocking[] = cloneDeep(newBindingStockingsJuvenile);
    record.isValid = false;
    record.bindingCode = bindingCode;
    bindingStockingCopy[record.key] = record;
    setNewBindingStockingsJuvenile(bindingStockingCopy);
  };

  const onChangeAnimalsJuvenile = (record: BindingStocking, value: string) => {
    const bindingStockingCopy: BindingStocking[] = cloneDeep(newBindingStockingsJuvenile);
    record.isValid = false;
    record.animals = parseInt(value);
    bindingStockingCopy[record.key] = record;
    setNewBindingStockingsJuvenile(bindingStockingCopy);
  };

  const onDeleteJuvenileRow = (key: number) => {
    const bindingStockingCopy: BindingStocking[] = cloneDeep(newBindingStockingsJuvenile);
    const newBindingStocking = bindingStockingCopy.filter((item, index) => index !== key);

    if (newBindingStocking.length === 0) {
      newBindingStocking.push(initialBindingStocking);
    }

    setNewBindingStockingsJuvenile(newBindingStocking);
  };

  const getDataBindingStocking = () => {
    return getBindingStockingData(newBindingStockingsJuvenile);
  };

  const addRowBindingStockingJuvenile = () => {
    if (newBindingStockingsJuvenile.length >= maximumNumberOfStockings) {
      openErrorNotification(t('stockings.maximumNumberOfStockings'));
      return;
    }

    const data: BindingStocking[] = [...newBindingStockingsJuvenile];
    data.push(initialBindingStocking);
    setNewBindingStockingsJuvenile(data);
  };

  const renderNewBindingStockingButton = () => {
    return (
      <NewBindingStockingButton
        theme={theme}
        bindingStockings={newBindingStockingsJuvenile}
        onClick={() => {
          if (stockingBindingRowIsFull(newBindingStockingsJuvenile)) {
            addRowBindingStockingJuvenile();
          }
        }}
      />
    );
  };

  const onChangeStockingParameter = (value: string) => {
    formNewStockingJuvenile.setFieldsValue({
      parameter: value,
      referenceCurveId: undefined,
    });

    setNewStockingParameter(value);
    setNewStockingReference(undefined);
    dispatch(stockingSlice.fetchReferenceCurves({ parameter: value, phaseType, companyId: company._id }));
  };

  const onChangeStockingReference = (value: string) => {
    formNewStockingJuvenile.setFieldsValue({
      referenceCurveId: value,
    });

    setNewStockingReference(value);
  };

  const renderReferenceCurve = () => {
    return (
      <Row gutter={16} className={styles.referenceCurve}>
        <Col span={24}>
          <Form.Item className={styles.formItem}>
            <LrvText
              className={styles.text}
              theme='light'
              text={t('stockings.referenceDescription')}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='parameter'
            label={`${t('production.filters.parameter')} (${t('common.optional')})`}
          >
            <LrvSelect
              theme={theme}
              autoFocus
              showSearch
              value={newStockingParameter}
              onChange={onChangeStockingParameter}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
            >
              <Option value={typeParam.AVG_WEIGHT}>{t('analysis.resultData.averageWeight')}</Option>
              <Option value={typeParam.AVG_LENGTH}>{t('analysis.resultData.averageLength')}</Option>
              <Option value={typeParam.UNIFORMITY}>{t('analysis.resultData.uniformity')}</Option>
            </LrvSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='referenceCurveId'
            label={`${t('stockings.reference')} (${t('common.optional')})`}
          >
            <LrvSelect
              theme={theme}
              autoFocus
              showSearch
              value={newStockingReference}
              onChange={onChangeStockingReference}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
              disabled={!newStockingParameter}
            >
              {referenceCurves.map((reference) => <Option key={reference._id} value={reference._id}>{reference.name}</Option>)}
            </LrvSelect>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <LrvModal
      title={t('stockings.newStocking')}
      theme={theme}
      className={cx(styles.newStockingModal, 'newStockingModal')}
      open={showCreateModalJuvenile}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_new_stocking', disabled: disabledButtonFormNewStocking, htmlType: 'submit', form: 'formNewStockingJuvenile' }}
      cancelButtonProps={{ id: 'cancel_new_stocking' }}
      onOk={createStocking}
      okText={t('stockings.create')}
      cancelText={t('stockings.cancel')}
      onCancel={() => {
        closeModal();
      }}
    >
      <LrvForm
        theme={theme}
        form={formNewStockingJuvenile}
        name='formNewStockingJuvenile'
        id='formNewStockingJuvenile'
        layout='vertical'
        onFieldsChange={() => {
          validateFormJuvenile();
        }}
      >
        {renderBindingStocking()}

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='campusIdJuvenile'
              label={t('stockings.campus')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvSelect
                theme={theme}
                autoFocus
                showSearch
                value={newStockingCampusJuvenile}
                onChange={onChangeStockingCampusJuvenile}
                suffixIcon={<Icon name='arrow-down-s' />}
                filterOption={filterOptionSelect}
                dropdownMatchSelectWidth={false}
              >
                {campusListForStockingCreation.map((campus) => <Option key={campus._id} value={campus._id}>{campus.name}</Option>)}
              </LrvSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='moduleIdJuvenile'
              label={t('stockings.module')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvSelect
                theme={theme}
                showSearch
                value={newStockingModuleJuvenile}
                onChange={onChangeStockingModuleJuvenile}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newStockingCampusJuvenile}
                filterOption={filterOptionSelect}
                dropdownMatchSelectWidth={false}
              >
                {modulesForm.map((module) => {
                  if (module.phaseType === phaseType) {
                    return <Option key={module._id} value={module._id}>{module.name}</Option>;
                  }
                })}
              </LrvSelect>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='tankId'
              label={t('stockings.containerTypes.JUVENILE')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvSelect
                theme={theme}
                showSearch
                value={newStockingTankJuvenile}
                onChange={onChangeStockingTankJuvenile}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newStockingModuleJuvenile}
                filterOption={filterOptionSelect}
                dropdownMatchSelectWidth={false}
              >
                {tanks.map((tank) => <Option disabled={tank.status !== tankStatuses.AVAILABLE} key={tank._id} value={tank._id}>{tank.name}</Option>)}
              </LrvSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='code'
              label={<ProductionCycleInfo tankId={newStockingTankJuvenile} label={t('stockings.productionCycle')} />}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvInput
                theme={theme}
                value={newStockingCodeJuvenile}
                style={{ textTransform: 'uppercase' }}
                maxLength={stockingCodeLength}
                onChange={(e) => setNewStockingCodeJuvenile(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='name'
              label={t('stockings.name')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvInput
                theme={theme}
                value={newStockingNameJuvenile}
                onChange={(e) => setNewStockingNameJuvenile(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='startDate'
              label={t('stockings.startDateJuvenile')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvDatePicker
                theme={theme}
                disabledDate={(currentDate) => disabledDateJuvenile({ currentDate, stockingDateRanges })}
                placeholder=''
                onChange={(date, dateString) => setNewStockingStartDateJuvenile(dateString)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='measureUnitJuvenile'
              label={selectedJuvenileContainerType === containerTypes.RACEWAY ? t('stockings.cubicMeters') : selectedJuvenileContainerType === containerTypes.PRE_HATCHERY ? t('stockings.hectares') : t('stockings.defaultMeasureJuvenile')}
              required
              rules={
                containerSelected.type === '' ?
                  [() => ({ validator (rule, value) { return validateNumber(value, false, 0); } })] :
                  [() => ({ validator (rule, value) { return validateNumber(value, false, containerVolumeLimits[containerSelected.type].min, containerVolumeLimits[containerSelected.type].max, t('stockings.volumeError', { min: containerVolumeLimits[containerSelected.type].min, max: containerVolumeLimits[containerSelected.type].max })); } })]
              }
            >
              <LrvInputNumber
                theme={theme}
                value={newStockingCubicMetersJuvenile}
                onChange={(value) => {
                  if (value) {
                    setNewStockingCubicMetersJuvenile(value);
                  }
                }}
              />
            </Form.Item>
          </Col>

          {!hasBindingStockingJuvenile &&
            <Col span={12}>
              <Form.Item
                name='numberJuvenile'
                label={t('stockings.numberJuvenile')}
                required
                rules={[() => ({ validator (rule, value) { return validateNumber(value, true, 1); } })]}
              >
                <LrvInputNumber
                  theme={theme}
                  value={newStockingNumberJuvenile}
                  formatter={value => applyThousandsSeparator(value)}
                  parser={value => applyParserThousandsSeparator(value)}
                  onChange={(value) => {
                    if (value) {
                      setNewStockingNumberJuvenile(value);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          }
        </Row>

        {
          !hasBindingStockingJuvenile &&
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='maturation'
                label={t('stockings.maturation')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <LrvSelect
                  theme={theme}
                  showSearch
                  value={newStockingMaturationJuvenile}
                  onChange={onChangeMaturationJuvenile}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  filterOption={filterOptionSelect}
                  dropdownMatchSelectWidth={false}
                >
                  {maturations.map((maturation) => <Option key={maturation._id} value={maturation._id}>{maturation.name}</Option>)}
                </LrvSelect>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='maturationCode'
                label={`${t('stockings.maturationCode')} (${t('common.optional')})`}
              >
                <LrvSelect
                  theme={theme}
                  showSearch
                  mode='tags'
                  disabled={!newStockingMaturationJuvenile}
                  value={newStockingMaturationCodeJuvenile}
                  onChange={value => {
                    const newValue = value.slice(-1);
                    setNewStockingMaturationCodeJuvenile(newValue[0]);
                    formNewStockingJuvenile.setFieldsValue({
                      maturationCode: newValue,
                    });
                  }}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  filterOption={filterOptionSelect}
                  dropdownMatchSelectWidth={false}
                >
                  {maturationCodes && maturationCodes.map((maturation) => <Option key={maturation._id} value={maturation._id}>{maturation.code}</Option>)}
                </LrvSelect>
              </Form.Item>
            </Col>
          </Row>
        }

        {
          !hasBindingStockingJuvenile &&
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='labName'
                label={`${t('stockings.labName')} (${t('common.optional')})`}
              >
                <LrvInput
                  theme={theme}
                  value={newStockingLabNameJuvenile}
                  onChange={(e) => setNewStockingLabNameJuvenile(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        }

        {
          hasBindingStockingJuvenile &&
          <>
            {renderBindingStockingRows()}
            {renderNewBindingStockingButton()}
          </>
        }

        {renderReferenceCurve()}

        <Col span={24}>
          <Form.Item
            name='invoiceImg'
            rules={[{ required: false }]}
            label={`${t('stockings.larvaReferralGuide')} (${t('common.optional')})`}
          >
            <div id='larvae_referral_guide'>
              <LrvDragger
                theme={theme}
                name='file'
                accept='image/png, image/jpeg'
                maxCount={1}
                listType='picture-card'
                showUploadList={false}
                beforeUpload={() => true}
                onChange={handleOnChange}
                customRequest={handleCustomRequest}
              >
                {imageUrl && !loadingImage ? <img src={imageUrl} alt='imageVoucher' style={{ width: '100%', height: 'inherit' }} /> : renderDraggerFile()}
              </LrvDragger>
              <canvas className={styles.canvasInvoice} ref={canvasRef} width={voucherWidth} height={voucherHeight} id='canvas' />
            </div>
          </Form.Item>
        </Col>

        {renderBindingCodeWarning()}
      </LrvForm>
    </LrvModal >
  );
};
