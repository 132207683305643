import i18next from 'i18next';

import { typeParam } from '../../../common/components/charts/ShadedPlot/helpers';
import { roundOneDecimal, stockingPhaseTypes } from '../../../config/commons';

import { ItemReferenceCurves, ReferenceCurves, Tank } from './interfaces';

const zList = [80, 100];
export const uniformityRange = [5, 12];

export const getXLabel = (phaseType: string) => {
  return phaseType === stockingPhaseTypes.LARVAE ? i18next.t('activeTanks.chart.xaxis.stage') : i18next.t('activeTanks.chart.xaxis.days');
};

export const getFactorK = (record: Tank) => {
  let percentageAnimalsAboveAverage = 0;
  if (record.analysis.animalsAboveConditionFactor) {
    const { animalsAboveConditionFactor, larvaeNumber: animals } = record.analysis;
    percentageAnimalsAboveAverage = roundOneDecimal(animalsAboveConditionFactor * 100 / animals);
  }

  return percentageAnimalsAboveAverage;
};

export const getDataLow = (props: { stages: number[]; weights: number[]; range: number }) => {
  const { stages, weights, range } = props;

  const points = [];

  for (let index = 0; index < stages.length; index++) {
    const stage = stages[index];

    for (let k = 0; k < zList.length; k++) {
      const element = zList[k];
      const pointLow = [stage, Math.round(weights[index] * range * 10) / 10, element];

      points.push(pointLow);
    }
  }

  const x: number[] = points.map(fila => fila[0]);
  const y: number[] = points.map(fila => fila[1]);

  return {
    x, y
  };
};

export const getDataHigh = (props: { stages: number[]; weights: number[]; range: number }) => {
  const { stages, weights, range } = props;
  const points = [];

  for (let index = 0; index < stages.length; index++) {
    const stage = stages[index];

    for (let k = 0; k < zList.length; k++) {
      const element = zList[k];
      const pointHigh = [stage, Math.round(weights[index] * range * 10) / 10, element];

      points.push(pointHigh);
    }
  }

  const x: number[] = points.map(fila => fila[0]);
  const y: number[] = points.map(fila => fila[1]);

  return {
    x, y
  };
};

const decimalToHex = (decimal: number): string => {
  const hex = decimal.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

const randomNumber = () => {
  return Math.floor(Math.random() * 256);
};

const generateColorHex = (): string => {
  const red = randomNumber();
  const green = randomNumber();
  const blue = randomNumber();
  return `#${decimalToHex(red)}${decimalToHex(green)}${decimalToHex(blue)}`;
};

export const getColors = (len: number) => {
  const colors = [];

  for (let index = 0; index < len; index++) {
    const color = generateColorHex();
    colors.push(color);
  }

  return colors;
};

export const rescaleUniformity = (props: { data: number[]; range: number[]; uniformity: number }) => {
  const { data, range, uniformity } = props;

  const min = Math.min(...data);
  let max = Math.max(...data);
  const [x, y] = range;

  if (max === min) {
    max += 1;
  }

  const xRescale = x + ((uniformity - min) * (y - x) / (max - min));

  if (xRescale < x) {
    return x;
  }

  if (xRescale > y) {
    return y;
  }

  return xRescale;
};

export const getScaleDivider = (props: { parameter: string; }) => {
  const { parameter } = props;

  switch (parameter) {
    case typeParam.AVG_WEIGHT:
      return 1000;

    case typeParam.AVG_LENGTH:
      return 10;

    default:
      return 1;
  }
};

export const getScaleReferenceCurve = (props: { parameter: string; phaseType: string; referenceCurve: ReferenceCurves; }) => {
  const { parameter, phaseType, referenceCurve } = props;

  if (phaseType === stockingPhaseTypes.LARVAE) {
    referenceCurve.values = referenceCurve.values.map((item: ItemReferenceCurves) => item);
    return referenceCurve;
  }

  referenceCurve.values = referenceCurve.values.map((item: ItemReferenceCurves) => {
    return {
      ...item,
      mean: item.mean / getScaleDivider({ parameter }),
    };
  });
  return referenceCurve;
};

export const getScaleYValues = (props: { parameter: string; phaseType: string; activeTanks: Tank[] }) => {
  const { parameter, phaseType, activeTanks } = props;

  switch (parameter) {
    case typeParam.AVG_WEIGHT:
    default: {
      if (phaseType === stockingPhaseTypes.LARVAE) {
        return activeTanks.map((tank) => tank.analysis.averageWeight);
      }

      return activeTanks.map((tank) => tank.analysis.averageWeight / 1000);
    }

    case typeParam.AVG_LENGTH: {
      if (phaseType === stockingPhaseTypes.LARVAE) {
        return activeTanks.map((tank) => tank.analysis.averageLength);
      }

      return activeTanks.map((tank) => tank.analysis.averageLength / 10);
    }

    case typeParam.PLG:
      return activeTanks.map((tank) => tank.analysis.larvaePerGram);
  }
};

export const getScaleActiveTanks = (props: { parameter: string; phaseType: string; activeTanks: Tank[]; }) => {
  const { parameter, phaseType, activeTanks } = props;

  if (phaseType === stockingPhaseTypes.LARVAE) {
    return activeTanks;
  }

  switch (parameter) {
    case typeParam.AVG_WEIGHT: {
      return activeTanks.map((value) => {
        return {
          ...value,
          averageWeight: value.analysis.averageWeight / 1000,
        };
      });
    }

    case typeParam.AVG_LENGTH: {
      return activeTanks.map((value) => {
        return {
          ...value,
          averageLength: value.analysis.averageLength / 10,
        };
      });
    }

    case typeParam.PLG:
    default:
      return activeTanks;
  }
};

export const getScaleYLimits = (props: { parameter: string; phaseType: string; yValues: number[] }) => {
  const { parameter, phaseType, yValues } = props;

  if (phaseType === stockingPhaseTypes.LARVAE) {
    return yValues;
  }

  switch (parameter) {
    case typeParam.AVG_WEIGHT: {
      return yValues.map((value) => value / 1000);
    }

    case typeParam.AVG_LENGTH: {
      return yValues.map((value) => value / 10);
    }

    case typeParam.PLG:
    default:
      return yValues;
  }
};

export const getWidthOfTheOtherElements = () => {
  const extraWidth = 40;

  if (window.innerWidth <= 950) {
    return extraWidth;
  }

  if (window.innerWidth <= 1420) {
    return extraWidth + 80;
  }

  return extraWidth + 264;
};
