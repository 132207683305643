import cx from 'classnames';
import { useEffect, } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Map from '../../Analysis/Detail/Map';
import { Store } from '../../../state/store.interfaces';
import Histogram from '../../Analysis/Detail/Histogram';
import { resetHeader } from '../../AppHeader/headerSlice';
import * as headerSlice from '../../AppHeader/headerSlice';
import Content from '../../../common/components/Content/Content';
import { getTitleCountAnimalChart } from '../../Analysis/helpers';
import { typeStyles } from '../../Analysis/Detail/histogram.helpers';
import GrowOutSizesChart from '../../Analysis/Detail/GrowOutSizesChart';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { fetchAnalysis } from '../../Analysis/Detail/detailAnalysisSlice';
import { LrvResult } from '../../../common/components/LrvResult/LrvResult';
import HorizontalBarsChart from '../../Analysis/Detail/HorizontalBarsChart';
import NotPermission from '../../../common/components/NotPermission/NotPermission';
import ResultAnalysis from '../../../common/components/ResultAnalysis/ResultAnalysis';
import { analysisErrors, analysisTypes, shareAnalysisError, typeHistogram } from '../../../config/commons';

import Header from './Header';
import Footer from './Footer';
import './AnalysisDetailPdf.scss';
import DataDetail from './DataDetail';
import AnalysisQRCode from './AnalysisQRCode';
import styles from './AnalysisDetailPdf.module.scss';

type TParams = { id: string; bins: string; accessToken: string; language: string; hours: string };

export default function AnalysisDetailPdf ({ match }: RouteComponentProps<TParams>) {
  const { id: analysisId, bins, accessToken, language, hours } = match.params;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { allAnalysis, analysis: analysisOriginal, currentIndex, isLoading, hasPermission, share: shareAnalysis } = useSelector((state: Store) => state.detailAnalysis);
  const currentAnalysis = (allAnalysis && allAnalysis.length > 0) ? allAnalysis[currentIndex] : analysisOriginal;

  const companyName = analysisOriginal.nameCompany;
  const companyId = analysisOriginal.companyId;
  const pin = currentAnalysis.pin;

  useEffect(() => {
    return () => {
      dispatch(resetHeader());
    };
  }, [dispatch]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    dispatch(fetchAnalysis({ analysisId, accessToken }));
  }, [dispatch, accessToken, analysisId]);

  useEffect(() => {
    if (!companyId) {
      return;
    }
    dispatch(headerSlice.fetchCompany(companyId, accessToken));
  }, [dispatch, companyId, accessToken]);

  if (!hasPermission) {
    return <NotPermission />;
  }

  if (isLoading) {
    return <div className={styles.spinner}>
      <DotSpinner />
    </div>;
  }

  if (shareAnalysis.status) {
    if (shareAnalysis.status === shareAnalysisError.NOT_SHARED) {
      const message: string = t('share.errors.notAvailable');
      return <ResultAnalysis id='not_shared' message={message} />;
    }

    if (shareAnalysis.status === shareAnalysisError.SHARE_EXPIRED) {
      const message: string = t('share.errors.notAvailable');
      return <ResultAnalysis id='shared_expired' message={message} />;
    }

    if (shareAnalysis.status === shareAnalysisError.NOT_AVAILABLE) {
      const message: string = t('share.errors.notAvailable');
      return <ResultAnalysis id='not_available' message={message} />;
    }
  }

  if (currentAnalysis.error && currentAnalysis.error.subType) {
    const message: string = typeErrorAnalysis(currentAnalysis.error.subType);
    return <div className={styles.showResult}>
      <LrvResult status='error' title={message} />
    </div>;
  }

  function typeErrorAnalysis (typeError: string) {
    let message = '';
    switch (typeError) {
      case analysisErrors.NOT_A_LARVAE_SAMPLE:
        message = t('detail.error.notALarvaeSample');
        break;

      case analysisErrors.NO_LARVAE_FOUND:
        message = t('detail.error.noLarvaeFound');
        break;

      case analysisErrors.LESS_MINIMUM_LARVAE:
        message = t('detail.error.lessMinimumLarvae');
        break;

      case analysisErrors.BLURRED_IMAGE:
        message = t('detail.error.blurredImage');
        break;

      case analysisErrors.DARK_IMAGE:
        message = t('detail.error.darkImage');
        break;
    }

    return message;
  }

  if (currentAnalysis._id === '') {
    return <div className={styles.spinner}>
      <DotSpinner />
    </div>;
  }

  function renderHistogramWeight () {
    return (
      <Content
        title={t('detail.pdf.histogramWeight')}
        headerClassName={styles.headerPadding}
        titleClassName={styles.titleContent}
        noPadding
        style={{ height: '411px' }}
      >
        <Histogram
          showOptions={false}
          minBin={parseInt(bins)}
          typeHistogramChart={typeHistogram.WEIGHT}
          stylesHistogram={typeStyles.PDF}
          containerClassName={styles.containerPdf}
        />
      </Content>
    );
  }

  function renderHistogramLength () {
    return (
      <Content
        title={t('detail.pdf.histogramLength')}
        noPadding
        headerClassName={styles.headerPadding}
        titleClassName={styles.titleContent}
        style={{ height: '411px' }}
      >
        <Histogram
          showOptions={false}
          minBin={parseInt(bins)}
          typeHistogramChart={typeHistogram.LENGTH}
          stylesHistogram={typeStyles.PDF}
          containerClassName={styles.containerPdf}
        />
      </Content>
    );
  }

  function renderHistogramPigmentation () {
    return (
      <Content
        title={t('detail.pdf.histogramPigmentation')}
        noPadding
        headerClassName={styles.headerPadding}
        titleClassName={styles.titleContent}
        style={{ height: '411px' }}
      >
        <Histogram
          showOptions={false}
          typeHistogramChart={typeHistogram.PIGMENTATION}
          stylesHistogram={typeStyles.PDF}
          containerClassName={styles.containerPdf}
        />
      </Content>
    );
  }

  const renderAnimalPerGroupChart = () => {
    return (
      <Content
        title={getTitleCountAnimalChart(currentAnalysis.phaseType)}
        headerClassName={styles.headerPadding}
        titleClassName={styles.titleContent}
        noPadding
      >
        <HorizontalBarsChart
          analysisData={currentAnalysis}
          typeChart='SHRIPMS_PER_GROUP'
          textClassName={styles.horizontalChartText}
          textInsideBarClassName={styles.textInsideBarClassName}
          textOutsideBarClassName={styles.textOutsideBarClassName}
          containerClassName={styles.containerPdf}
          typeStyles='PDF'
        />
      </Content>
    );
  };

  const renderWeightGroupChart = () => {
    return (
      <Content
        title={t('detail.chart.weightGroup')}
        headerClassName={styles.headerPadding}
        titleClassName={styles.titleContent}
        noPadding
      >
        <HorizontalBarsChart
          typeChart='CUMMULATIVE_WEIGHT_PER_GROUP'
          analysisData={currentAnalysis}
          textClassName={styles.horizontalChartText}
          textInsideBarClassName={styles.textInsideBarClassName}
          textOutsideBarClassName={styles.textOutsideBarClassName}
          containerClassName={styles.containerPdf}
          typeStyles='PDF'
        />
      </Content>
    );
  };

  const renderWholeSizeChart = (props: { containerClassName: string; title: string }) => {
    const { containerClassName, title } = props;

    return (
      <Content
        title={title}
        headerClassName={styles.headerPadding}
        titleClassName={styles.titleContent}
        noPadding
      >
        <GrowOutSizesChart
          analysisData={analysisOriginal}
          typeChart='GROW_OUT_WHOLE'
          textClassName={styles.horizontalChartText}
          textInsideBarClassName={styles.textInsideBarClassName}
          textOutsideBarClassName={styles.textOutsideBarClassName}
          containerClassName={containerClassName}
          typeStyles='PDF'
        />
      </Content>
    );
  };

  const renderTailSizeChart = (props: { containerClassName: string; title: string }) => {
    const { containerClassName, title } = props;

    return (
      <Content
        title={title}
        headerClassName={styles.headerPadding}
        titleClassName={styles.titleContent}
        noPadding
      >
        <GrowOutSizesChart
          typeChart='GROW_OUT_TAIL'
          analysisData={analysisOriginal}
          textClassName={styles.horizontalChartText}
          textInsideBarClassName={styles.textInsideBarClassName}
          textOutsideBarClassName={styles.textOutsideBarClassName}
          containerClassName={containerClassName}
          typeStyles='PDF'
        />
      </Content>
    );
  };

  const renderMap = () => {
    return (
      <Content
        title={t('detail.map')}
        headerClassName={styles.headerPadding}
        titleClassName={styles.titleContent}
        noPadding
      >
        <Map containerClassName={styles.containerPdf} notAvailableStyle={styles.notAvailable} showPreviousNextButtons={false} />
      </Content>
    );
  };

  const renderBody = () => {
    if (analysisOriginal.type === analysisTypes.CONSOLIDATED_ADULT_ANALYSIS) {
      return (
        <div className={styles.rowChart}>
          <div className={styles.colChart}>
            {renderHistogramWeight()}
            {renderHistogramLength()}
            {renderHistogramPigmentation()}
          </div>

          <div className={styles.colChart}>
            {renderWholeSizeChart({ containerClassName: styles.containerGrowOutSizesPdf1, title: t('detail.chart.commercialSizes') + ': ' + t('detail.growOutSizes.whole') })}
            {renderTailSizeChart({ containerClassName: styles.containerGrowOutSizesPdf2, title: t('detail.chart.commercialSizes') + ': ' + t('detail.growOutSizes.tail') })}
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={styles.rowChart}>
          {renderHistogramWeight()}
          {renderHistogramLength()}
        </div>

        <div className={styles.rowChart}>
          {renderAnimalPerGroupChart()}
          {renderWeightGroupChart()}
        </div>

        <div className={styles.rowChart}>
          {renderHistogramPigmentation()}
          {renderMap()}
        </div>
      </>
    );
  };

  function renderDetail () {
    return <div className={cx(styles.containerMain, 'analysisDetailPdf')}>
      <Header
        companyName={companyName}
        stockingBindingCode={currentAnalysis.sowingId?.stockingBindingCode}
      />

      <div className={styles.rowData}>
        <DataDetail hours={parseInt(hours)} />
        <AnalysisQRCode id={analysisId} pin={pin} />
      </div>

      {renderBody()}
      <Footer
        stockingBindingCode={currentAnalysis.sowingId?.stockingBindingCode}
      />
    </div>;
  }

  return renderDetail();
}