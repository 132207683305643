import moment from 'moment';

import { DATE_FORMATS } from '../../../config/commons';
import { getCurrentElementHeight } from '../../../utils/dimensions';

import { StockingQuadrant } from './interfaces';

export const COLOR_LEGEND_WIDTH = 200;

export const SUCCESS_QUADRANT_PARAMETERS = {
  DENSITY: 'DENSITY',
  MATURATION_NAME: 'MATURATION_NAME',
  GENETIC_CODE: 'GENETIC_CODE',
  PREVIOUS_ORIGIN: 'PREVIOUS_ORIGIN',
  CONTAINER: 'CONTAINER',
  MODULE: 'MODULE',
};

export function getXAxisMinMax (props: { quadrantData: StockingQuadrant[] }) {
  const { quadrantData } = props;
  const values = quadrantData.map((item) => item.weeklyGrowth);

  const min = Math.min(...values);
  const max = Math.max(...values);

  return {
    min: min - (max * 0.02),
    max: max + (max * 0.02),
  };
}

export const renderTickFormatXAxis = (props: { domainValue: d3.AxisDomain; }) => {
  const { domainValue } = props;
  return domainValue.toString();
};

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters } = props;

  const headerHeight = 64;
  const axisXHeight = 24;
  const labelTopHeight = 24;
  const extraHeight = 64;

  const value = getCurrentElementHeight(filters) + axisXHeight + headerHeight + extraHeight + labelTopHeight;
  return value;
};

const getTanksPanelWidth = (element: React.RefObject<HTMLDivElement>) => {
  return element.current?.offsetWidth || 0;
};

interface WidthProps {
  tanksPanel: React.RefObject<HTMLDivElement>;
}

export const getWidthOfTheOtherElements = (props: WidthProps) => {
  const { tanksPanel } = props;
  const extraWidth = 40;

  if (window.innerWidth <= 950) {
    return getTanksPanelWidth(tanksPanel) + extraWidth;
  }

  if (window.innerWidth <= 1420) {
    return getTanksPanelWidth(tanksPanel) + extraWidth + 90;
  }

  return getTanksPanelWidth(tanksPanel) + 250 + extraWidth;
};

interface LeftPositionProps {
  marginLeft: number;
  tooltipDialogWidth: number;
  bubbleWidth: number;
  width: number;
}

export const getPrevStatChartLeftPosition = (props: LeftPositionProps) => {
  const { marginLeft, tooltipDialogWidth, bubbleWidth, width } = props;

  const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

  let value = 0;
  if (marginLeft + tooltipTotalWidth < width) {
    value = marginLeft + (tooltipDialogWidth / 2) + bubbleWidth - 32;
  } else {
    value = marginLeft - (tooltipDialogWidth / 2) - bubbleWidth - 32;
  }

  return `${value}px`;
};

export const getFromDate = (lastDays: number) => {
  return moment(new Date()).subtract(lastDays, 'days').format(DATE_FORMATS.YYYY_MM_DD);
};

export const getToDate = () => {
  return moment(new Date()).format(DATE_FORMATS.YYYY_MM_DD);
};

export const disabledDateFrom = (current: moment.MomentInput, toDate: Date) => {
  const minDays = 180;
  const start = moment().subtract(minDays, 'days');
  const end = moment(toDate);
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledDateTo = (current: moment.MomentInput, fromDate: Date) => {
  const start = moment(fromDate).add(1, 'days');
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};
