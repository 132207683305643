import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';
import { capitalize } from 'lodash';

import resetIconSvg from '../../../assets/reset.svg';
import { getCurrentTheme } from '../../../helpers/theme';
import { applyThousandsSeparator } from '../../../utils/strings';
import { GenericParam } from '../../../common/interfaces/commons';
import { rescaleUniformity, uniformityRange } from '../ActiveTanksDashboard/helpers';
import { THEME, roundTwoDecimals, stockingPhaseTypes } from '../../../config/commons';

import { StockingQuadrant } from './interfaces';
import styles from './SuccessQuadrantD3.module.scss';
import { getXAxisMinMax, renderTickFormatXAxis, SUCCESS_QUADRANT_PARAMETERS, COLOR_LEGEND_WIDTH } from './helpers';

let zoomDone = false;
let isLightTheme = true;
let idleTimeout: NodeJS.Timeout | null;

const TIME_TRANSITION = 300;
const TICK_PADDING = 8;
const TOOLTIP_WIDTH = 220;
const START_POSITION_Y = -8;

interface Props {
  container: HTMLDivElement | null;
  quadrantData: StockingQuadrant[];
  firstStage: number;
  lastStage: number;
  width: number;
  height: number;
  phaseType: string;
  parameter: string;
}

export default class SuccessQuadrantD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined> = d3.select<SVGSVGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));
  groupMain: d3.Selection<SVGGElement, unknown, null, undefined>;

  x: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  y: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();

  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  tooltipOption: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  xAxis: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  yAxis: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  intermediaLineAxisY: d3.Selection<SVGLineElement, unknown, null, undefined> = d3.select<SVGLineElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'line'));
  intermediaLineAxisX: d3.Selection<SVGLineElement, unknown, null, undefined> = d3.select<SVGLineElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'line'));

  quadrantData: StockingQuadrant[] = [];
  dataY1: number[] = [];
  dataY2: number[] = [];

  width: number;
  height: number;
  margin = { top: 30, right: 30, bottom: 30, left: 30 };

  firstStage = 0;
  lastStage = 0;

  phaseType: string;
  parameter: string;

  clip: d3.Selection<d3.BaseType, unknown, null, undefined> = d3.select<d3.BaseType, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'defs'));;
  brush: d3.BrushBehavior<unknown> = d3.brush();
  gClipPath: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  // eslint-disable-next-line
  constructor(props: Props) {
    const { container, quadrantData, firstStage, lastStage, width, height, phaseType, parameter } = props;

    this.container = container;
    this.quadrantData = quadrantData;
    this.phaseType = phaseType;
    this.parameter = parameter;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('svg').remove();

    const theme = getCurrentTheme();
    isLightTheme = theme === THEME.LIGHT;

    this.svg = d3.select(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', this.width + this.margin.left + this.margin.right + COLOR_LEGEND_WIDTH)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    if (firstStage === lastStage && firstStage === 0) {
      const { max, min } = getXAxisMinMax({ quadrantData });
      this.firstStage = min;
      this.lastStage = max;
    } else {
      this.firstStage = firstStage;
      this.lastStage = lastStage;
    }

    this.createBrushElement();
    this.updateDataPoints();
    this.createTooltip();
    this.renderTooltipOption();
  }

  createGClipPathElement = () => {
    const { groupMain, height, width } = this;

    this.clip = groupMain.append('defs')
      .attr('id', 'defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', width + 16)
      .attr('height', height + 14)
      .attr('x', START_POSITION_Y)
      .attr('y', START_POSITION_Y);

    this.gClipPath = groupMain.append('g')
      .attr('id', 'gClipPath')
      .attr('clip-path', 'url(#clip)');
  };

  createBrushElement = () => {
    const { height, width } = this;

    this.brush = d3.brush()
      .extent([[0, 0], [width, height]])
      .on('end', (event) => this.onBrush(event));
  };

  onBrush = (event: GenericParam) => {
    if (!event.sourceEvent || !event.selection) {
      const { firstStage, lastStage } = this;
      const { maxY, minY } = this.getYDomainData();

      if (!idleTimeout) {
        return idleTimeout = setTimeout(() => {
          idleTimeout = null;
        }, 350);
      }
      this.resetBrush({ x0: firstStage, y0: minY, x1: lastStage, y1: maxY });
      return;
    }

    const [[x0, y0], [x1, y1]] = event.selection;
    this.applyBrush({ x0, y0, x1, y1 });
  }

  applyBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = true;
    this.x.domain([x0, x1].map(this.x.invert));
    this.y.domain([y1, y0].map(this.y.invert));

    // eslint-disable-next-line
    this.gClipPath.select('.brush').call(this.brush.move as any, null);

    this.refreshLines();
    this.refreshBrush();
  }

  resetBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = false;
    this.x.domain([x0, x1]);
    this.y.domain([y0, y1]);

    this.refreshLines();
    this.refreshBrush();
    this.hideTooltipOption();
  }

  refreshBrush = () => {
    const { height } = this;

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat((d) => renderTickFormatXAxis({ domainValue: d }))
      .tickSize(-height)
      .tickPadding(TICK_PADDING);

    const axisLeft = d3.axisLeft(this.y)
      .tickFormat((d) => d3.format('')(d as number))
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.yAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);

    this.deleteDomainAxisX();

    this.gClipPath
      .selectAll('circle')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('cx', (point: any) => this.x(point.weeklyGrowth))
      // eslint-disable-next-line
      .attr('cy', (point: any) => this.y(point.survivalRate));

    this.renderResetBrush();
  }

  refreshLines = () => {
    const { firstStage, lastStage } = this;

    const { maxY, minY } = this.getYDomainData();

    const positionX = (firstStage + lastStage) / 2;
    const positionY = (maxY + minY) / 2;

    this.intermediaLineAxisY
      .transition()
      .duration(TIME_TRANSITION)
      .attr('x1', this.x(positionX))
      .attr('x2', this.x(positionX));

    this.intermediaLineAxisX
      .transition()
      .duration(TIME_TRANSITION)
      .attr('y1', this.y(positionY))
      .attr('y2', this.y(positionY));
  };

  updateDataPoints = () => {
    this.buildAxisX();
    this.buildAxisY();
    this.renderResetBrush();

    this.deleteDomainAxisX();
    this.createGClipPathElement();

    this.drawXAxis();
    this.drawYAxis();
    this.renderIntermediateLinesAxis();
    this.renderPoints();
  };

  createTooltip = () => {
    const { container } = this;

    d3.select(container).select('#tooltip').remove();

    this.tooltip = d3.select(container)
      .append('div')
      .attr('id', 'tooltip')
      .style('display', 'none')
      .attr('class', styles.tooltip);
  };

  renderTooltipOption = () => {
    const childDiv = d3.select<HTMLDivElement, unknown>('#chart');
    const parentNode = childDiv?.node()?.parentNode as HTMLElement;
    d3.select(parentNode).select('#tooltipOption').remove();

    this.tooltipOption = d3.select(parentNode)
      .append('div')
      .attr('id', 'tooltipOption')
      .attr('class', styles.tooltipOption);
  };

  getDensityColorScale = () => {
    const { quadrantData } = this;

    const densities = quadrantData.map((item) => item.stockingDensity);

    const min = Math.min(...densities);
    const max = Math.max(...densities);

    return d3.scaleSequential<string, never>()
      .domain([min, max])
      .interpolator(d3.interpolateCool);
  };

  getMaturationColorScale = () => {
    const { quadrantData } = this;

    const maturationNames = quadrantData.map((item) => item.maturationName);
    const uniqueData = Array.from(new Set(maturationNames));

    return d3.scaleOrdinal(d3.schemeCategory10)
      .domain(uniqueData);
  };

  getGeneticCodeColorScale = () => {
    const { quadrantData } = this;

    const maturationCodes = quadrantData.map((item) => item.maturationCode || '');
    const uniqueData = Array.from(new Set(maturationCodes));

    return d3.scaleOrdinal(d3.schemeCategory10)
      .domain(uniqueData);
  };

  getPreviouOriginColorScale = () => {
    const { quadrantData } = this;

    const previousOrigin = quadrantData.map((item) => item.previousOrigin);
    const uniqueData = Array.from(new Set(previousOrigin));

    return d3.scaleOrdinal(d3.schemeCategory10)
      .domain(uniqueData);
  };

  getContainerColorScale = () => {
    const { quadrantData } = this;

    const names = quadrantData.map((item) => item.containerName);
    const uniqueData = Array.from(new Set(names));

    return d3.scaleOrdinal(d3.schemeCategory10)
      .domain(uniqueData);
  };

  getModuleColorScale = () => {
    const { quadrantData } = this;

    const names = quadrantData.map((item) => item.moduleName);
    const uniqueData = Array.from(new Set(names));

    return d3.scaleOrdinal(d3.schemeCategory10)
      .domain(uniqueData);
  };

  getColor = (props: { analysis: StockingQuadrant }) => {
    const { analysis } = props;

    switch (this.parameter) {
      case SUCCESS_QUADRANT_PARAMETERS.DENSITY:
      default: {
        const colorScale = this.getDensityColorScale();
        return colorScale(analysis.stockingDensity);
      }

      case SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME: {
        const colorScale = this.getMaturationColorScale();
        return colorScale(analysis.maturationName);
      }

      case SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE: {
        const colorScale = this.getGeneticCodeColorScale();
        return colorScale(analysis.maturationCode || '');
      }

      case SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN: {
        const colorScale = this.getPreviouOriginColorScale();
        return colorScale(analysis.previousOrigin);
      }

      case SUCCESS_QUADRANT_PARAMETERS.CONTAINER: {
        const colorScale = this.getContainerColorScale();
        return colorScale(analysis.containerName);
      }

      case SUCCESS_QUADRANT_PARAMETERS.MODULE: {
        const colorScale = this.getModuleColorScale();
        return colorScale(analysis.moduleName);
      }
    }
  };

  renderDensityLegend = () => {
    const { quadrantData, groupMain, height, width, margin } = this;
    const densities = quadrantData.map((item) => item.stockingDensity);

    const min = Math.min(...densities);
    const max = Math.max(...densities);
    const colorDomain = [min, max];

    const colorScale = this.getDensityColorScale();
    const size = (margin.right / 1.5);

    // Create a group for the color bar
    const colorBarGroup = groupMain.append('g')
      .attr('id', 'colorBarGroup')
      .attr('transform', `translate(${width + size}, ${START_POSITION_Y})`);

    // Gradient for the color bar
    const defs = groupMain.append('defs');
    const linearGradient = defs.append('linearGradient')
      .attr('id', 'linear-gradient')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '0%')
      .attr('y2', '0%');

    // Manually create stops
    const stops = d3.range(colorDomain[0], colorDomain[1], (colorDomain[1] - colorDomain[0]) / 10).map(value => ({
      offset: `${(value - colorDomain[0]) / (colorDomain[1] - colorDomain[0]) * 100}%`,
      color: colorScale(value)
    }));

    linearGradient.selectAll('stop')
      .data(stops)
      .enter()
      .append('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color);

    // Draw the rectangle and fill with gradient
    colorBarGroup.append('rect')
      .attr('width', size)
      .attr('height', height - START_POSITION_Y)
      .style('fill', 'url(#linear-gradient)');

    // Create the axis scale and draw the axis
    const axisScale = d3.scaleLinear()
      .domain(colorDomain)
      .range([height - START_POSITION_Y, 0]);

    const axisRight = d3.axisRight(axisScale)
      .ticks(10);

    colorBarGroup.append('g')
      .attr('transform', 'translate(20, 0)')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axisRight);
  };

  renderStringLegend = (props: { uniqueData: string[] }) => {
    const { uniqueData } = props;
    const { groupMain, width, margin } = this;

    const colorScale = d3.scaleOrdinal(d3.schemeCategory10)
      .domain(uniqueData);

    const size = (margin.right / 1.5);
    const spacing = 8;

    const colorBarGroup = groupMain.append('g')
      .attr('id', 'colorBarGroup')
      .attr('transform', `translate(${width + size}, ${START_POSITION_Y})`);

    // Draw colored rectangles for each category
    uniqueData.forEach((d, i) => {
      colorBarGroup.append('rect')
        .attr('x', 0)
        .attr('y', i * (size + spacing))
        .attr('width', size)
        .attr('height', size)
        .attr('fill', colorScale(d));

      const text = colorBarGroup.append('text')
        .attr('x', size + 5)
        .attr('y', (i * (size + spacing)) + (size / 2))
        .attr('dy', '0.35em')
        .attr('class', isLightTheme ? styles.textLight : styles.textDark)
        .text(d || '-');

      text.each(function () {
        const textElement = d3.select(this);
        let textLength = textElement?.node()?.getComputedTextLength();
        const originalText = textElement.text();
        let text = textElement.text();
        const maxWidth = COLOR_LEGEND_WIDTH - 20;

        if (textLength && textLength > maxWidth) {
          while (textLength && textLength > maxWidth && text.length > 0) {
            text = text.slice(0, -1);
            textElement.text(`${text}...`);
            textLength = textElement?.node()?.getComputedTextLength();
          }

          textElement.append('title').text(originalText);
        }
      });
    });
  };

  renderMaturationLegend = () => {
    const { quadrantData } = this;

    const maturationNames = quadrantData.map((item) => item.maturationName);
    const uniqueData = Array.from(new Set(maturationNames));
    this.renderStringLegend({ uniqueData });
  };

  renderGeneticCodeLegend = () => {
    const { quadrantData } = this;

    const maturationCodes = quadrantData.map((item) => item.maturationCode || '');
    const uniqueData = Array.from(new Set(maturationCodes));
    this.renderStringLegend({ uniqueData });
  };

  renderPreviousOriginLegend = () => {
    const { quadrantData } = this;

    const previousOrigin = quadrantData.map((item) => item.previousOrigin);
    const uniqueData = Array.from(new Set(previousOrigin));
    this.renderStringLegend({ uniqueData });
  };

  renderContainerLegend = () => {
    const { quadrantData } = this;

    const names = quadrantData.map((item) => item.containerName);
    const uniqueData = Array.from(new Set(names));
    this.renderStringLegend({ uniqueData });
  };

  renderModuleLegend = () => {
    const { quadrantData } = this;

    const names = quadrantData.map((item) => item.moduleName);
    const uniqueData = Array.from(new Set(names));
    this.renderStringLegend({ uniqueData });
  };

  renderColorLegend = () => {
    const { container } = this;

    d3.select(container).select('#colorBarGroup').remove();

    switch (this.parameter) {
      case SUCCESS_QUADRANT_PARAMETERS.DENSITY:
      default: {
        this.renderDensityLegend();
        break;
      }

      case SUCCESS_QUADRANT_PARAMETERS.MATURATION_NAME: {
        this.renderMaturationLegend();
        break;
      }

      case SUCCESS_QUADRANT_PARAMETERS.GENETIC_CODE: {
        this.renderGeneticCodeLegend();
        break;
      }

      case SUCCESS_QUADRANT_PARAMETERS.PREVIOUS_ORIGIN: {
        this.renderPreviousOriginLegend();
        break;
      }

      case SUCCESS_QUADRANT_PARAMETERS.CONTAINER: {
        this.renderContainerLegend();
        break;
      }

      case SUCCESS_QUADRANT_PARAMETERS.MODULE: {
        this.renderModuleLegend();
        break;
      }
    }
  }

  renderPoints () {
    const { gClipPath, x, y, quadrantData } = this;
    const uniformityWeight = quadrantData.map((item) => item.uniformity);

    gClipPath.append('g')
      .selectAll('circle')
      .data(quadrantData)
      .enter()
      .append('circle')
      .attr('class', cx('circle', styles.circle, isLightTheme ? styles.circleLight : styles.circleDark))
      .attr('r', (d, index) => rescaleUniformity({ data: uniformityWeight, range: uniformityRange, uniformity: uniformityWeight[index] }))
      .attr('cx', (point) => x(point.weeklyGrowth))
      .attr('cy', (point) => y(point.survivalRate))
      .attr('fill', (quadrantData) => this.getColor({ analysis: quadrantData }))
      .on('mouseover', (event, quadrantData: StockingQuadrant) => this.onMouseover({ event, quadrantData, uniformityWeight }))
      .on('mousemove', (event, quadrantData: StockingQuadrant) => this.onMousemove({ event, quadrantData, uniformityWeight }))
      .on('mouseleave', (event, quadrantData: StockingQuadrant) => this.onMouseleave({ event, quadrantData, uniformityWeight }))
      .on('click', (event, quadrantData: StockingQuadrant) => this.onClickCircle(quadrantData));
  }

  onMouseover = (props: { event: GenericParam; quadrantData: StockingQuadrant; uniformityWeight: number[] }) => {
    const { event, quadrantData, uniformityWeight } = props;

    this.tooltip
      .style('display', 'flex');

    d3.select(event.currentTarget)
      .attr('r', rescaleUniformity({ data: uniformityWeight, range: uniformityRange, uniformity: quadrantData.uniformity }) * 1.10)
      .style('stroke-width', 1.5);
  };

  generateContentTooltip = (quadrantData: StockingQuadrant) => {
    const unitWeight = this.phaseType === stockingPhaseTypes.LARVAE ? i18next.t('quadrant.miligramPerDay') : i18next.t('quadrant.gramPerWeek');

    return `
        <h3>
          ${quadrantData.stockingName}
        </h3>
        <ul>
          <li>${i18next.t('quadrant.survivalRate')}: <strong>${roundTwoDecimals(quadrantData.survivalRate)} % </strong></li>
          <li>${i18next.t('quadrant.stockingDensity')}: <strong>${applyThousandsSeparator(quadrantData.stockingDensity)} ${quadrantData.densityUnit}</strong></li>
          <li>${i18next.t('quadrant.uniformity')}: <strong> ${roundTwoDecimals(quadrantData.uniformity)} % </strong> </li>
          <li>${i18next.t('quadrant.weeklyGrowth')}: <strong> ${roundTwoDecimals(quadrantData.weeklyGrowth)} ${unitWeight} </strong> </li>
          <li>${i18next.t('quadrant.maturation')}: <strong> ${capitalize(quadrantData.maturationName)} </strong> </li>
          <li>${i18next.t('quadrant.container')}: <strong> ${capitalize(quadrantData.containerName)} </strong> </li>
          <li>${i18next.t('quadrant.module')}: <strong> ${capitalize(quadrantData.moduleName)} </strong> </li>
          ${quadrantData.maturationCode ? `<li>${i18next.t('quadrant.maturationCode')}: <strong> ${quadrantData.maturationCode} </strong> </li>` : ''}
          ${quadrantData.previousOrigin ? `<li>${i18next.t('quadrant.previousOrigin')}: <strong class="${styles.previousOrigin}" title="${quadrantData.previousOrigin}"> ${quadrantData.previousOrigin} </strong> </li>` : ''}
        </ul>
      `;
  };

  onMousemove = (props: { event: GenericParam; quadrantData: StockingQuadrant; uniformityWeight: number[] }) => {
    const { event, quadrantData, uniformityWeight } = props;

    const svgRect = this.svg.node()?.getBoundingClientRect();
    const radio = rescaleUniformity({ data: uniformityWeight, range: uniformityRange, uniformity: quadrantData.uniformity });
    const stage = this.x(quadrantData.weeklyGrowth);

    let mouseX = event.clientX - (svgRect?.left || 0) + radio;
    const mouseY = event.clientY - (svgRect?.top || 0);
    mouseX = stage + TOOLTIP_WIDTH < this.width ? mouseX : mouseX - (TOOLTIP_WIDTH);

    this.tooltip
      .html(this.generateContentTooltip(quadrantData))
      .style('left', mouseX + 'px')
      .style('top', mouseY + 'px');
  };

  onMouseleave = (props: { event: GenericParam; quadrantData: StockingQuadrant; uniformityWeight: number[] }) => {
    const { event, quadrantData, uniformityWeight } = props;

    this.tooltip
      .style('display', 'none');

    d3.select(event.currentTarget)
      .attr('r', rescaleUniformity({ data: uniformityWeight, range: uniformityRange, uniformity: quadrantData.uniformity }))
      .style('stroke-width', 1);
  };

  onClickCircle = (quadrantData: StockingQuadrant) => {
    const url = `/production/stockings/${quadrantData.stockingId}`;
    window.open(url, '_blank');
  };

  deleteDomainAxisX () {
    const { container } = this;
    d3.select(container).select('#content').select('#axisX').selectAll('.domain').remove();
    d3.select(container).select('#content').select('#axisX').selectAll('.tick').select('line').remove();
  }

  renderLinesAxis = () => {
    const { container, groupMain, height, width } = this;

    d3.select(container).selectAll('.lineAxisY').remove();
    d3.select(container).selectAll('.lineAxisX').remove();

    groupMain.append('line')
      .attr('class', cx(styles.lines, 'lineAxisY'))
      .attr('y1', START_POSITION_Y)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', 0);

    groupMain.append('line')
      .attr('class', cx(styles.lines, 'lineAxisY'))
      .attr('y1', START_POSITION_Y)
      .attr('y2', height)
      .attr('x1', width)
      .attr('x2', width);

    groupMain.append('line')
      .attr('class', cx(styles.lines, 'lineAxisX'))
      .attr('y1', height)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', width);

    groupMain.append('line')
      .attr('class', cx(styles.lines, 'lineAxisX'))
      .attr('y1', START_POSITION_Y)
      .attr('y2', START_POSITION_Y)
      .attr('x1', 0)
      .attr('x2', width);
  }

  renderIntermediateLinesAxis = () => {
    const { container, gClipPath, height, width, brush, firstStage, lastStage } = this;

    d3.select(container).select('#intermediaLineAxisY').remove();
    d3.select(container).select('#intermediaLineAxisX').remove();

    d3.select(container).selectAll('.brush').remove();

    gClipPath
      .append('g')
      .attr('class', 'brush')
      .call(brush);

    const lines = gClipPath.append('g');
    const { maxY, minY } = this.getYDomainData();
    const positionX = (firstStage + lastStage) / 2;
    const positionY = (maxY + minY) / 2;

    this.intermediaLineAxisY = lines.append('line')
      .attr('id', 'intermediaLineAxisY')
      .attr('class', styles.lines)
      .attr('y1', START_POSITION_Y)
      .attr('y2', height)
      .attr('x1', this.x(positionX))
      .attr('x2', this.x(positionX));

    this.intermediaLineAxisX = lines.append('line')
      .attr('id', 'intermediaLineAxisX')
      .attr('class', styles.lines)
      .attr('y1', this.y(positionY))
      .attr('y2', this.y(positionY))
      .attr('x1', 0)
      .attr('x2', width);
  }

  renderTriangle = () => {
    const { container, margin, height } = this;

    const triangleMargins = {
      left: margin.left - 5,
      top: -(height + margin.top + margin.bottom + 11),
    };

    d3.select(container).selectAll('.triangle').remove();

    d3.select(container)
      .append('div')
      .attr('id', 'triangle')
      .attr('class', cx('triangle', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, 0px)`);
  };

  hideTooltipOption = () => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 0);
  };

  showTooltipOption = (marginRight: number) => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 1);

    this.tooltipOption.html(i18next.t('shadedplot.reset'))
      .style('right', (marginRight + 28) + 'px')
      .style('top', START_POSITION_Y + 'px');
  };

  renderResetBrush = () => {
    const { margin, width, svg } = this;
    const marginLeft = width + margin.left - (margin.right / 2);

    svg.select('#resetBrushButton').remove();
    svg.select('#defsBackgroundIcon').remove();

    if (!zoomDone) {
      return;
    }

    svg
      .append('image')
      .attr('id', 'resetBrushButton')
      .attr('class', styles.resetButton)
      .attr('x', marginLeft)
      .attr('y', 0)
      .attr('xlink:href', resetIconSvg)
      .attr('width', 16)
      .attr('height', 16)
      .attr('cursor', 'pointer')
      .attr('filter', 'url(#background-color-filter)')
      .on('mouseover', () => this.showTooltipOption(margin.right + COLOR_LEGEND_WIDTH))
      .on('mouseout', this.hideTooltipOption)
      .on('click', () => {
        const { firstStage, lastStage } = this;
        const { maxY, minY } = this.getYDomainData();
        this.resetBrush({ x0: firstStage, y0: minY, x1: lastStage, y1: maxY });
      });

    svg
      .append('defs')
      .attr('id', 'defsBackgroundIcon')
      .append('filter')
      .attr('id', 'background-color-filter')
      .append('feFlood')
      .attr('flood-color', '#959595')
      .attr('result', 'backgroundColor');

    svg.select('#background-color-filter').append('feComposite')
      .attr('id', 'feCompositeBackgroundIcon')
      .attr('in', 'backgroundColor')
      .attr('in2', 'SourceGraphic')
      .attr('operator', 'atop');
  };

  updateAxis () {
    const { height } = this;

    this.renderTriangle();
    this.renderLinesAxis();
    this.renderResetBrush();

    this.buildAxisX();
    this.buildAxisY();

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat((d) => renderTickFormatXAxis({ domainValue: d }))
      .tickSize(-height)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .attr('fill', 'transparent')
      .attr('transform', `translate(0, ${this.height})`)
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.deleteDomainAxisX();

    const axiLeft = d3.axisLeft(this.y)
      .tickFormat((d) => d3.format('')(d as number))
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.yAxis.transition()
      .duration(TIME_TRANSITION)
      .call(axiLeft);
  }

  updateData () {
    this.updateAxis();
    this.createGClipPathElement();
    this.renderIntermediateLinesAxis();
    this.renderPoints();
    this.renderColorLegend();
  }

  refreshChart (props: { quadrantData: StockingQuadrant[]; firstStage: number; lastStage: number; width: number; height: number; phaseType: string; parameter: string; }) {
    const { quadrantData, phaseType, firstStage, lastStage, width, height, parameter } = props;
    const { container, tooltip } = this;

    const theme = getCurrentTheme();
    isLightTheme = theme === THEME.LIGHT;
    zoomDone = false;

    this.quadrantData = quadrantData;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.phaseType = phaseType;
    this.parameter = parameter;

    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).selectAll('.points').remove();

    d3.select(container).select('#defs').remove();
    d3.select(container).select('#gClipPath').remove();

    tooltip.style('display', 'none');

    this.updateSize(width, height);
    this.updateData();
    this.createTooltip();
    this.renderTooltipOption();
  }

  buildAxisX () {
    const { width, firstStage, lastStage } = this;

    this.x = d3.scaleLinear()
      .domain([firstStage, lastStage])
      .range([0, width]);
  }

  buildAxisY () {
    const { height } = this;
    const { maxY, minY } = this.getYDomainData();

    this.y = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  }

  getYDomainData = () => {
    const { quadrantData } = this;

    const margin = 2;
    const dataY: number[] = quadrantData.map((item) => item.survivalRate);

    let minY = Math.min(...dataY);
    let maxY = Math.max(...dataY);

    minY = minY - margin;
    maxY = maxY + margin;

    return {
      maxY,
      minY,
    };
  }

  drawXAxis () {
    const { height, groupMain } = this;

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat((d) => renderTickFormatXAxis({ domainValue: d }))
      .tickSize(-height)
      .tickPadding(TICK_PADDING);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axisBottom);

    this.deleteDomainAxisX();
  }

  drawYAxis () {
    const { groupMain } = this;

    const axis = d3.axisLeft(this.y)
      .tickFormat((d) => d3.format('')(d as number))
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.yAxis = groupMain.append('g')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  resize = (props: { width: number, height: number }) => {
    const { width, height } = props;
    const { container } = this;

    d3.select(container).select('#content').selectAll('*').remove();

    this.updateSize(width, height);
    this.updateAxis();
    this.createBrushElement();
    this.updateDataPoints();
    this.renderColorLegend();
    this.createTooltip();
    this.renderTooltipOption();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right + COLOR_LEGEND_WIDTH;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };
}
