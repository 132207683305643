import { Frequency } from '../Analysis/Detail/interfaces';
import { Maturation, MaturationCode } from '../Clients/interfaces';

export enum SORT_TYPE {
  ASC = 'asc',
  DESC = 'desc',
  DEFAULT = 'default',
}

export enum STOCKINGS_SORT {
  CAMPUS_NAME = 'campusName',
  MODULE_NAME = 'moduleName',
  TANK_NAME = 'tankName',
  STOCKING_DATE = 'stockingDate',
}

export interface FetchStockingParams {
  page: number;
  companyId?: string;
  campusId?: string;
  moduleId?: string;
  tankId?: string;
  maturationId?: string;
  maturationCodeId?: string;
  searchValue?: string;
  searchType?: string;
  phaseType: string;
  stockingsToShow?: string;
  sortByCampusName?: SORT_TYPE;
  sortByModuleName?: SORT_TYPE;
  sortByTankName?: SORT_TYPE;
  sortByStockingDate?: SORT_TYPE;
}

export interface FetchStockingProps {
  companyId: string;
  campusId?: string;
  moduleId?: string;
  tankId?: string;
  page: number;
  phaseType: string;
  stockingsToShow: string;
}

export interface CreateStockingProps {
  stockingData: StockingLarvae | StockingJuvenile | StockingGrowOut;
  paramsToFetchStocking: FetchStockingProps;
  closeModal: () => void;
}

export interface CreateStockingsProps {
  data: StockingsInBatch,
  paramsToFetchStocking: FetchStockingProps;
  closeModal: () => void;
}

export interface PropsStockingByBindingCode {
  code: string;
  index: number;
  bindingStockings: BindingStocking[];
}

export interface UpdateReferralGuideProps {
  id: string;
  stockingData: { tankId: string; code: string; userId: string; invoiceImg: string };
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
}

export interface EditStockingProps {
  id: string;
  stockingData: UpdateLarvaeData | UpdateJuvenileData | UpdateGrowOutData;
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
}

export interface StockingPayload {
  total: number;
  limit: number;
  skip: number;
  data: [];
}

export interface GrowthDeltaStage {
  stage: number;
  delta: number;
}

export interface GrowthDeltaStocking {
  stockingId: string;
  data: GrowthDeltaStage[];
  enabled?: boolean;
  name?: string;
}

export interface Campus {
  _id: string;
  name: string;
  code: string;
  status: string;
  province?: string;
  phaseType: string;
}

export interface Module {
  _id: string;
  name: string;
  code: string;
  phaseType: string;
}

export interface Container {
  _id: string;
  name: string;
  code: string;
  type: string;
  volume: number;
  status: string;
}

export interface DestinationStocking {
  _id: string;
  name: string;
  transferRecord?: boolean;
  naupliusNumber: number;
  juvenilesNumber: number;
  growOutNumber: number;
}

export interface FinishStockingsState {
  showModalFinish: boolean;
  showModalEnable: boolean;
  showFinishStockingInfo: boolean;
  isLoadingFinish: boolean;
  hasErrorEnable: boolean;
  isLoadingEnable: boolean;
  plgHarvest: number;
  averageWeight: number;
  stockingFinishedData: StockingFinished;
  dataDestinationStockings: DataDestinationStocking[];
}

export interface TransferStockingsState {
  showTransferStockingInfo: boolean;
  isLoadingTransfer: boolean;
  isLoadingUndoTransfer: boolean;
  isLoadingEnableTransfer: boolean;
  transfers: Transfer[];
  isLoading: boolean;
  campuses: Campus[];
}

export interface HarvestsStockingState {
  showHarvestsInfo: boolean;
  isLoadingHarvests: boolean;
  isLoadingUndoHarvest: boolean;
  harvests: PartialHarvest[];
}

export interface ReferenceCurve {
  _id: string;
  name: string;
}

export interface StockingsState {
  campusesForm: Campus[];
  modulesForm: Module[];
  tanksForm: Container[];
  campusesMove: Campus[];
  modulesMove: Module[];
  tanksMove: Container[];
  referenceCurves: ReferenceCurve[];
  filters: {
    campuses: Campus[];
    modules: Module[];
    tanks: Container[];
    maturations: Maturation[];
    maturationCodes: MaturationCode[];
    stockingsToShow: string;
    searchValue: string;
    showSearchInput: boolean;
    searchType: string;
    sorts: {
      byCampusName: SORT_TYPE;
      byModuleName: SORT_TYPE;
      byTankName: SORT_TYPE;
      byStockingDate: SORT_TYPE;
    };
  };
  maturations: Maturation[];
  maturationCodes: MaturationCode[];
  list: [];
  total: number;
  skip: number;
  limit: number;
  currentPage: number;
  showModalEdit: boolean;
  showModalMove: boolean;
  isLoading: boolean;
  isLoadingStocking: boolean;
  isLoadingEdit: boolean;
  isLoadingCreate: boolean;
  hasErrorEdit: boolean;
  isLoadingFinish: boolean;
  isLoadingToMove: boolean;
  existErrorToMove: boolean;
  selectedStocking: Stocking;
  selectedCampus: Campus | undefined;
  selectedModuleId: string | undefined;
  selectedTankId: string | undefined;
  selectedMaturationId: string | undefined;
  selectedMaturationCodeId: string | undefined;
  xlsxReport: {
    fromDate: string;
    toDate: string;
    data: {};
    showModal: boolean;
    isLoading: boolean;
    typeFile: string;
    typeReportCsv: string;
  };
  publishLarviaTrade: {
    showModal: boolean;
    animalsOffered: number;
    isPublic: boolean;
    isLoadingToPublish: boolean;
  };
  stockingDateRanges: StockingDateRange;
  currentTabSetting: string;
  showCreateModalLarvae: boolean;
  showCreateModalJuvenile: boolean;
  showCreateModalGrowOut: boolean;
  stageOptions: StockingStageOptions;
}

export interface BindingStocking {
  key: number;
  _id: string;
  bindingCode: string;
  code: string;
  name: string;
  isValid: boolean;
  validatedCode: boolean;
  animals: number;
  maturationId: string;
  maturationCode: string;
  phaseType: string;
  labName: string;
  labId: string;
  endDate: string;
  stage: number;
}

export interface BindingStockingTable {
  key: number;
  bindingCode: string;
  animals: number;
  isValid: boolean;
  validatedCode: boolean;
}

export interface StockingsInBatch {
  campusId: string;
  code: string;
  startDate: string;
  customStage?: CustomStage;
  moduleId: string;
  tanks: TankOfStocking[];
  daysToInitialStage: number;
}

export interface TankOfStocking {
  key: number;
  tankId: string;
  tankName: string;
  litersNumber: number;
  name: string;
  naupliusNumber: number;
  maturationId: string;
  maturationCode?: string;
}

export interface Stocking {
  _id: string;
  code: string;
  bindingStockings: BindingStocking[];
  stockingBindingCode?: string;
  name: string;
  type: string;
  status: string;
  startDate: string;
  startDateJuvenile: string;
  startDateGrowOut: string;
  endDate: string;
  naupliusNumber: number;
  juvenilesNumber: number;
  growOutNumber: number;
  litersNumber: number;
  cubicMeters: number;
  hectares: number;
  createdAt: string;
  stage: number;
  shouldFinish: boolean;
  comment: string;
  poundsHarvested: number;
  harvestQuantity: number;
  averageHarvestedWeight?: number;
  phaseType: string;
  companyId: string;
  larvaePerGram: number;
  campusId: Campus;
  tankId: {
    _id: string;
    name: string;
    status?: string;
    type?: string;
  };
  maturationId: {
    _id: string;
    name: string;
    codes: MaturationCode[];
  };
  maturationCode: string;
  moduleId: {
    _id: string;
    name: string;
  };
  analysisCount: number;
  labName?: string;
  voucher?: {
    url: string;
    key: string;
    presignedUrl: string;
  };
  referenceCurveId?: {
    _id: string;
    campusId: string;
    companyId: string;
    name: string;
    type: string;
    phaseType: string;
    values: [];
  };
  isPublic: boolean;
  isArchived: boolean;
  survivalRate?: number;
  archivedDate?: string;
  transfers: Transfer[];
  harvests: PartialHarvest[];
  customStage?: CustomStage;
  populations: StockingPopulation[];
  daysToInitialStage: number;
}

export interface StockingPopulation {
  populationDate: string;
  animalsNumber: number;
}

export interface Transfer {
  transferDate: string;
  poundsTransferred: number;
  animalsTransferred: number;
  averageHarvestedWeight: number;
  stockingId: string;
  stockingName: string;
  status: string;
  comment: string;
}

export interface PartialHarvest {
  _id: string;
  harvestDate: string,
  harvestQuantity: number,
  poundsHarvested: number,
  averageHarvestedWeight: number,
  larvaePerGram: number,
  comment: string,
}

export interface GlobalSurvivalRateMetric {
  _id: string;
  species: string;
  type: string;
  sum: number;
  survivalAvg: number;
  totalN: number;
}

export interface CompanySurvivalRateMetric {
  _id: string;
  companyId: string;
  species: string;
  type: string;
  sum: number;
  survivalAvg: number;
  totalN: number;
}

export interface SurvivalRate {
  year: number;
  week: number;
  virtualWeek: number;
  showYearLine: boolean;
  avgSurvivalRate: number;
}

export interface SurvivalRatePayload {
  global: GlobalSurvivalRateMetric;
  company: CompanySurvivalRateMetric;
  survivalRate: [];
}

export interface SurvivalRateHistogramPayload {
  frequencies: number[];
  limits: number[];
}

export interface SurvivalRateFiltersPayload {
  campus?: Campus;
  moduleId?: string;
  tankId?: string;
  fromDate: string;
  toDate: string;
}

export interface SurvivalRateState {
  global: GlobalSurvivalRateMetric;
  company: CompanySurvivalRateMetric | undefined;
  survivalRate: [];
  survivalRateHistogram: Frequency;
  isLoadingStockingPdf: boolean;
  campuses: Campus[];
  modules: Module[];
  tanks: Container[];
  isLoading: boolean;
  tabSelected: string;
  filters: {
    campus?: Campus;
    moduleId?: string;
    tankId?: string;
    fromDate: string;
    toDate: string;
  };
}

export interface StockingDateRange {
  larvae: {
    startDateLarvae: Range;
  };
  juvenile: {
    startDateLarvae: Range;
    startDateJuvenile: Range;
  };
  growOut: {
    startDateGrowOut: Range;
  };
}

export interface Range {
  min: number;
  max: number;
}


export interface StockingFinished {
  larvae: {
    larvaePerGram: Range;
  };
  juvenile: {
    averageHarvestedWeight: {
      min: number;
    };
  };
  growOut: {
    averageHarvestedWeight: {
      min: number;
    };
  };
}

export interface StockingToBeFinishedPayload {
  total: number;
  limit: number;
  skip: number;
  data: Stocking[];
}

export interface StockingToBeFinishedState {
  total: number;
  limit: number;
  skip: number;
  stockings: Stocking[];
  isLoading: boolean;
  showStockingNotification: boolean;
}

export interface FinishStockingData {
  status: string;
  comment?: string;
  endDate?: string;
  harvestDate?: string;
  harvestQuantity?: string;
  plg?: string;
  phaseType: string;
  poundsHarvested?: string;
  averageHarvestedWeight?: string
}

export interface FinishStockingProps {
  stockingId: string;
  stockingData: FinishStockingData;
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
  onCreateBindingCode?: Function;
}

export interface EnableStockingProps {
  stockingId: string;
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
}

export interface DataDestinationStocking {
  animalsNumber?: number;
  campusId: string;
  code: string;
  cubicMeters?: number;
  hectares?: number;
  litersNumber?: number;
  moduleId: string;
  name: string;
  startDate: string;
  tankId: string;
  stockingId?: string;
  type: string;
  labName: string;
  labId: string;
  customStage?: CustomStage;
  phaseType?: string;
  volume?: number;
  tankType?: string;
}

export interface TransferStockingDataProps {
  status: string;
  plgTransferred?: number;
  averageHarvestedWeight: number;
  animalsTransferred: number;
  transferDate: string;
  originPhaseType: string;
  destinationPhaseType: string;
  poundsTransferred: number;
  destinationStockings: DataDestinationStocking[];
}

export interface TransferFullStockingDataProps {
  status: string;
  comment?: string;
  harvestQuantity: number;
  endDate: string;
}

export interface TransferStockingProps {
  stockingId: string;
  stockingData: TransferStockingDataProps;
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
}

export interface TransferFullStockingProps {
  stockingId: string;
  stockingData: TransferFullStockingDataProps;
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
}

export interface UndoTransferStockingProps {
  originStockingId: string;
  destinationStockingId: string;
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
}

export interface UndoHarvestStockingProps {
  originStockingId: string;
  partialHarvestId: string;
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
}

export interface EnableTransferStockingProps {
  stockingId: string;
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
}

export interface EnableTransferStockingProps {
  stockingId: string;
  makeFetchStockings: boolean;
  paramsToFetchStocking: FetchStockingProps;
}

export interface CustomStage {
  key: string;
  value: number;
}

export interface StockingStageOptions {
  [key: string]: number;
}

export interface NewStocking {
  code: string;
  name: string;
  campusId: string;
  userId: string;
  invoiceImg?: string;
  tankId: string;
  maturationId?: string | undefined;
  maturationCode?: string | undefined;
  phaseType: string;
  bindingStockings?: BindingStocking[];
  labId?: string | undefined;
  labName?: string | undefined;
  voucher?: {
    url: string;
    key: string;
  };
  referenceCurveId?: string;
}

export interface StockingLarvae extends NewStocking {
  startDate?: string | undefined;
  naupliusNumber?: number;
  litersNumber?: number;
  customStage?: CustomStage;
  daysToInitialStage: number;
}

export interface StockingJuvenile extends NewStocking {
  startDate?: string | undefined;
  startDateJuvenile?: string | undefined;
  juvenilesNumber: number | undefined;
  cubicMeters?: number | undefined;
  hectares?: number | undefined;
}

export interface StockingGrowOut extends NewStocking {
  type?: string | undefined;
  startDateGrowOut?: string | undefined;
  growOutNumber: number | undefined;
  cubicMeters?: number | undefined;
  hectares?: number | undefined;
}

export interface UpdateLarvae {
  name: string;
  phaseType: string;
  maturationId: string;
  maturationCode?: string | undefined;
  labId?: string;
  labName?: string;
  bindingStockings?: BindingStocking[];
  referenceCurveId?: string;
}

export interface UpdateLarvaeData extends UpdateLarvae {
  naupliusNumber: number;
  litersNumber: number | undefined;
  startDate: string;
  customStage?: CustomStage;
  daysToInitialStage: number;
}

export interface UpdateJuvenileData extends UpdateLarvae {
  juvenilesNumber: number;
  cubicMeters?: number;
  hectares?: number;
  startDate?: string;
  startDateJuvenile: string;
}

export interface UpdateGrowOutData extends UpdateLarvae {
  growOutNumber: number;
  cubicMeters?: number;
  hectares?: number;
  startDateGrowOut: string;
  type: string;
}

export interface PopulationData {
  populationDate: string;
  animalsNumber: number;
  density: number;
  survivalRate: number;
  stockingId?: string;
  animalsTransferred: number;
  type: PopulationTypes;
}

export enum PopulationTypes {
  HARVEST = 'HARVEST',
  MANUAL = 'MANUAL',
}

export default PopulationTypes;

export interface EditPopulationStockingState {
  selectedStocking: Stocking;
  stockingTransfers: Stocking[];
  isLoadingStocking: boolean;
  isUpdating: boolean;
  showModal: boolean;
}
