import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { Row, Space, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { RootState } from '../../../state/store';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { filterOptionSelect } from '../../../utils/select';
import { changeHeader } from '../../AppHeader/headerSlice';
import { handleResizeEvent } from '../../../utils/dimensions';
import { THEME, stockingPhaseTypes, unitPhaseTypes } from '../../../config/commons';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { lineColor, rectColor, selectedTickStoke } from '../../../common/components/charts/ShadedPlot/helpers';

import PrevStatsD3 from './PrevStatsD3';
import styles from './PrevStats.module.scss';
import { CustomTagProps } from './interfaces';
import * as prevStatsSlice from './prevStatsSlice';
import { calcPrevStatsStages, getHeightOfTheOtherElements, getNewPrevTankColor, getWidthOfTheOtherElements, groupDataSource } from './helpers';

let chart: PrevStatsD3 | null;

const { Option } = Select;

const PrevStats = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const colorLine = isLightTheme ? lineColor.light : lineColor.dark;
  const colorFillRect = isLightTheme ? rectColor.light : rectColor.dark;
  const tickStroke = isLightTheme ? selectedTickStoke.light : selectedTickStoke.dark;

  const refChartMain = useRef<HTMLDivElement>(null);
  const refFilters = useRef<HTMLDivElement>(null);
  const refTanksPanel = useRef<HTMLDivElement>(null);

  const { company: selectedCompany } = useSelector((state: Store) => state.header);
  const { isLoadingPrevStats, unitsList: units, modulesList: modules, unitSelected, moduleSelected, availableTanksByModules, prevTanksSelected, dataSource } = useSelector((state: RootState) => state.prevState);

  const [height, setHeight] = useState(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters }));
  const [width, setWidth] = useState(window.innerWidth - getWidthOfTheOtherElements({ tanksPanel: refTanksPanel }));

  useEffect(() => {
    dispatch(changeHeader({ title: t('performance.title') }));

    return () => {
      chart = null;
      dispatch(prevStatsSlice.setChartData([]));
      dispatch(prevStatsSlice.setPrevTanksAvailable([]));
      dispatch(prevStatsSlice.setTanksSelected([]));
    };
  }, [dispatch]);

  useEffect(() => {
    const params = {
      companyId: selectedCompany._id,
      unitPhaseType: unitPhaseTypes.PRODUCTION,
    };
    dispatch(prevStatsSlice.setUnitSelected(''));
    dispatch(prevStatsSlice.setModuleSelected(''));
    dispatch(prevStatsSlice.setModulesList([]));
    dispatch(prevStatsSlice.fetchUnitsList(params));
  }, [selectedCompany]);

  useEffect(() => {
    if (!dataSource.length || dataSource.length === 0, !selectedCompany?._id) {
      return ;
    }

    function showChartMain () {
      const data = dataSource;
      const maxStage = selectedCompany.maxStage;
      const prevStatsByTanks = groupDataSource(data, prevTanksSelected);
      const stages = calcPrevStatsStages(prevStatsByTanks, maxStage);

      const firstStage = stages[0];
      const lastStage = stages[1];

      if (prevStatsByTanks.length === 0 && !!chart) {
        chart.refreshChart({ colorFillRect, prevTanksSelected, colorLine, dataMetric: prevStatsByTanks, firstStage, height, width, lastStage, selectedTickStoke: tickStroke });
        return ;
      }

      if (chart) {
        chart.refreshChart({ colorFillRect, prevTanksSelected, colorLine, dataMetric: prevStatsByTanks, firstStage, height, width, lastStage, selectedTickStoke: tickStroke });
        return ;
      }

      const props = {
        colorFillRect,
        colorLine,
        companyData: selectedCompany,
        container: refChartMain.current,
        dataMetric: prevStatsByTanks,
        firstStage,
        lastStage,
        width,
        height,
        showLabels: prevStatsByTanks.length === 1,
        selectedTickStoke: tickStroke,
        prevTanksSelected,
      };

      chart = new PrevStatsD3(props);
    }

    showChartMain();
  }, [dataSource, tickStroke, colorFillRect, colorLine, chart, selectedCompany, width, height, prevTanksSelected]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements({ tanksPanel: refTanksPanel }));
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters }));
    });
  }, []);

  useEffect(() => {
    if (!selectedCompany._id || !dataSource.length) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements({ tanksPanel: refTanksPanel }));
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters }));
  }, [selectedCompany._id, dataSource]);

  useEffect(() => {
    chart && chart.resize(width, height);
  }, [width, height]);

  const addTankSelected = useCallback((tankId: string) => {
    const prevTanksSelectedCopy = cloneDeep(prevTanksSelected);
    if (prevTanksSelectedCopy.length === 5) {
      return ;
    }

    const newColors = getNewPrevTankColor(prevTanksSelectedCopy);
    const { lineColor, strokeColor } = newColors;
    prevTanksSelectedCopy.push({ tankId, lineColor, strokeColor });

    dispatch(prevStatsSlice.setTanksSelected(prevTanksSelectedCopy));
  }, [prevTanksSelected]);

  const removeTankSelected = useCallback((tankId: string) => {
    const prevTanksSelectedCopy = cloneDeep(prevTanksSelected);
    const index = prevTanksSelected.findIndex(item => item.tankId === tankId);
    if (index >= 0) {
      prevTanksSelectedCopy.splice(index, 1);
    }
    dispatch(prevStatsSlice.setTanksSelected(prevTanksSelectedCopy));
  }, [prevTanksSelected]);

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_units'
        theme={theme}
        className={className}
        value={unitSelected || undefined}
        onChange={(value) => {
          dispatch(prevStatsSlice.setChartData([]));
          dispatch(prevStatsSlice.setUnitSelected(value));
          dispatch(prevStatsSlice.setModuleSelected(''));
          dispatch(prevStatsSlice.setChartData([]));
          dispatch(prevStatsSlice.setTanksSelected([]));
          dispatch(prevStatsSlice.setPrevTanksAvailable([]));
          dispatch(prevStatsSlice.fetchModulesList(value));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('performance.unit')}
        placeholder={t('performance.selectUnit')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        {units.map((unit) =>
          <Option key={unit._id} value={unit._id}>{unit.name}</Option>)
        }
      </LrvSelect>
    );
  };

  const renderModulesDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_modules'
        theme={theme}
        className={className}
        value={moduleSelected || undefined}
        onChange={(value) => {
          dispatch(prevStatsSlice.setModuleSelected(value));
          dispatch(prevStatsSlice.fetchChartDataSource({ companyId: selectedCompany._id, moduleId: value }));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('performance.module')}
        placeholder={t('performance.selectModule')}
        dropdownMatchSelectWidth={false}
        disabled={!unitSelected}
        showSearch
        filterOption={filterOptionSelect}
      >
        {modules.map((module) =>
          <Option key={module._id} value={module._id}>{module.name}</Option>)
        }
      </LrvSelect>
    );
  };

  const renderTag = (props: CustomTagProps) => {
    const { value, label } = props;
    const prevTankItem = prevTanksSelected.find(item => item.tankId === value);

    return (
      <div
        style={{ backgroundColor: prevTankItem?.lineColor || undefined }}
        className={styles.tagContainer}
      >
        <span className={styles.tagItem}>
          <span className={styles.tagLabel}>{label}</span>
          <span className={styles.tagClosable} onClick={() => removeTankSelected(value)}>
            <Icon name='close' />
          </span>
        </span>
      </div>
    );
  };

  const renderAvailableTanks = () => {
    if (!showChart()) {
      return ;
    }

    const items: JSX.Element[] = [];

    const phaseTypesArray = [stockingPhaseTypes.ADULT, stockingPhaseTypes.JUVENILE, stockingPhaseTypes.LARVAE];

    for (let j = 0; j < phaseTypesArray.length; j++) {
      const tanksElements: JSX.Element[] = [];
      const phaseType = phaseTypesArray[j];
      const availableTanksByPhase = availableTanksByModules.filter(item => item.phaseType === phaseType);

      for (let i = 0; i < availableTanksByPhase.length; i++) {
        const element = availableTanksByPhase[i];
        const tanks = element.tanks;
        const prevTanksFiltered = prevTanksSelected.filter(item => tanks.some(tank => tank._id === item.tankId));
        const values = prevTanksFiltered.map(item => item.tankId);

        const tankElement = (
          <LrvSelect
            id={`dropdown_pre_module_${i}`}
            key={element._id}
            theme={theme}
            containerClassName={styles.prevModulesContainer}
            popupClassName={prevTanksSelected.length < 5 ? undefined : styles.disabledOptions}
            className={styles.prevModules}
            tagRender={renderTag}
            value={values}
            onDeselect={removeTankSelected}
            onSelect={addTankSelected}
            suffixIcon={<Icon name='arrow-down-s' />}
            title={element.name}
            placeholder={t('performance.selectTanks')}
            dropdownMatchSelectWidth={false}
            mode='multiple'
            showSearch
            filterOption={filterOptionSelect}
          >
            {tanks.map((tank) =>
              <Option key={tank._id} value={tank._id} >{tank.name}</Option>)
            }
          </LrvSelect>
        );
        tanksElements.push(tankElement);
      }

      if (tanksElements.length > 0) {
        const item = (
          <div className={styles.phaseTypeContainer}>
            <div className={styles.divider}>
              <LrvText theme={theme} text={t(`performance.tanksDivider.${phaseType}`)} />
            </div>
            {tanksElements}
          </div>
        );
  
        items.push(item);
      }
    }

    return (
      <div
        ref={refTanksPanel}
        className={cx(styles.availableTanks, isLightTheme ? styles.availableTanksLight : styles.availableTanksDark)}
      >
        {items}
      </div>
    );
  };

  const showChart = () => {
    return !(!moduleSelected || modules.length === 0 || !dataSource.length);
  };

  const renderContent = () => {
    if (isLoadingPrevStats) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (!showChart()) {
      return (
        <div className={styles.center} >
          <LrvEmpty description={moduleSelected ? t('performance.emptyData') : t('performance.emptyModule')} theme={theme} />
        </div>
      );
    }

    return null;
  };

  const renderEmptyPrevTanksMessage = () => {
    if (prevTanksSelected.length > 0) {
      return ;
    }

    return (
      <div className={cx(styles.centerAbsolute, isLightTheme ? styles.lightBackground : styles.darkBackground)} >
        <div className={styles.emptyContainer}>
          <LrvEmpty description={t('performance.emptyOrigin')} theme={theme} />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.prevStats}>
      <Row ref={refFilters} className={styles.filtersContainer}>
        <Space className={styles.filters} align='end'>
          {renderUnitsDropdown({ className: styles.select, theme })}
          {renderModulesDropdown({ className: styles.select, theme })}
        </Space>
      </Row>
      {renderContent()}

      <div className={styles.main}>
        <div className={styles.chart} style={{ display: showChart() ? '' : 'none' }}>
          <div className={styles.labelAxisY}>
            <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('performance.chart.yaxis')} />
          </div>
          <div>
            {renderEmptyPrevTanksMessage()}
            <div ref={refChartMain} className={styles.prevStatsChart} />
          </div>

          <div className={styles.labelAxisX}>
            <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('performance.chart.xaxis')} />
          </div>
        </div>

        {renderAvailableTanks()}
      </div>
    </div>

  );
};

export default PrevStats;