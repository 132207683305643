import cx from 'classnames';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState, useCallback } from 'react';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { roundOneDecimal, typeHistogram, stockingPhaseTypes, THEME } from '../../../config/commons';
import { getParentElementHeight, getParentElementWidth, handleResizeEvent } from '../../../utils/dimensions';

import LineaD3 from './LineaD3';
import HistogramD3 from './HistogramD3';
import styles from './Histogram.module.scss';
import { setBin } from './detailAnalysisSlice';
import { Dataset, Frequency } from './interfaces';
import { getAverage, getDataset, getFrequency, typeStyles, getAverageOriginal, getLimits, getAnimalsAboveAverage, getAnimalsBelowAverage, getUnit } from './histogram.helpers';

let chart: HistogramD3 | null;
let linea: LineaD3 | null;

interface Props {
  showOptions?: boolean;
  minBin?: number;
  typeHistogramChart?: string;
  stylesHistogram?: string;
  containerClassName?: string;
  theme?: 'dark' | 'light';
}

export default function Histogram (props: Props) {
  const {
    showOptions = true,
    minBin = 6,
    typeHistogramChart = typeHistogram.WEIGHT,
    stylesHistogram = typeStyles.DEFAULT,
    containerClassName = styles.container,
    theme = 'dark',
  } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const refChart = useRef<HTMLDivElement>(null);
  const refLinea = useRef<HTMLDivElement>(null);
  const lineaHeight = 42;

  const [numberBins, setNumberBins] = useState(minBin);
  const [histogram, setHistogram] = useState(typeHistogramChart);

  const { analysis: analysisOriginal } = useSelector((state: Store) => state.detailAnalysis);
  const isLightTheme = theme === THEME.LIGHT;

  const getHeightHistogram = useCallback(() => {
    if (histogram === typeHistogram.PIGMENTATION) {
      return getParentElementHeight(refChart) + 8;
    }

    return getParentElementHeight(refChart) - lineaHeight;
  }, [histogram]);

  useEffect(() => {
    dispatch(setBin(minBin));

    return () => {
      chart = null;
      linea = null;
    };
  }, [dispatch, minBin]);

  useEffect(() => {
    handleResizeEvent(() => {
      const width = getParentElementWidth(refChart);
      const height = getHeightHistogram();

      chart && chart.resize(width, height);
      linea && linea.resize(width, lineaHeight);
    });
  }, [getHeightHistogram]);

  useEffect(() => {
    const animals = analysisOriginal.resultData.larvaeNumber;
    const frequency: Frequency = getFrequency(histogram, analysisOriginal, numberBins);

    const dataset: Dataset[] = getDataset(frequency, animals);
    const averageOriginal = getAverageOriginal(histogram, analysisOriginal);
    const limits: number[] = getLimits(histogram, frequency, averageOriginal);

    const animalsAboveAverage = getAnimalsAboveAverage(histogram, analysisOriginal);
    const animalsBelowAverage = getAnimalsBelowAverage(histogram, analysisOriginal);
    const unit = getUnit(histogram, analysisOriginal);
    const average = getAverage(histogram, analysisOriginal) as number;

    const percentageAnimalsAboveAverage = roundOneDecimal(animalsAboveAverage * 100 / animals);
    const percentageAnimalsBelowAverage = roundOneDecimal(animalsBelowAverage * 100 / animals);

    const width = getParentElementWidth(refChart);
    const height = getHeightHistogram();

    if (chart && showOptions) {
      chart.resize(width, height);
      chart.refreshHistogram(dataset, histogram, limits, average, theme, unit);

      if (!showAnimalsAboveBelowAverage()) {
        return;
      }

      linea = new LineaD3({
        container: refLinea.current,
        height: lineaHeight,
        width,
        histogram,
        limits,
        theme,
        typeStyles: stylesHistogram,
        average,
        animalsAboveAverage,
        animalsBelowAverage,
        percentageAnimalsAboveAverage,
        percentageAnimalsBelowAverage,
      });
      return;
    }

    chart = new HistogramD3(refChart.current, dataset, histogram, limits, average, width, height, stylesHistogram, theme, unit);

    if (!showAnimalsAboveBelowAverage()) {
      return;
    }

    linea = new LineaD3({
      container: refLinea.current,
      height: lineaHeight,
      width,
      histogram,
      limits,
      theme,
      typeStyles: stylesHistogram,
      average,
      animalsAboveAverage,
      animalsBelowAverage,
      percentageAnimalsAboveAverage,
      percentageAnimalsBelowAverage,
    });
  }, [getHeightHistogram, analysisOriginal, numberBins, histogram, showOptions, stylesHistogram, theme]);

  const showAnimalsAboveBelowAverage = () => {
    return (histogram === typeHistogram.WEIGHT || histogram === typeHistogram.LENGTH || histogram === typeHistogram.CONDITION_FACTOR) &&
      (analysisOriginal.resultData.animalsAboveAverageLength);
  };

  const removeBin = () => {
    if (numberBins > 6 && histogram !== typeHistogram.PIGMENTATION && histogram !== typeHistogram.CONDITION_FACTOR) {
      const bin = numberBins - 1;
      dispatch(setBin(bin));
      setNumberBins(bin);
    }
  };

  const addBin = () => {
    if (numberBins < 10 && histogram !== typeHistogram.PIGMENTATION && histogram !== typeHistogram.CONDITION_FACTOR) {
      const bin = numberBins + 1;
      dispatch(setBin(bin));
      setNumberBins(bin);
    }
  };

  function renderOptions () {
    if (!showOptions) {
      return null;
    }

    return (
      <div className={styles.actions}>
        <Radio.Group
          className={styles.typeHistogram}
          value={histogram}
          onChange={(event) => setHistogram(event.target.value)} size='small'
        >
          <Radio.Button
            className={cx(styles.weight, styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, histogram === typeHistogram.WEIGHT ? styles.radioSelected : '')}
            value={typeHistogram.WEIGHT}
          >
            <div id='radio_button_weight'>{t('detail.weight')}</div>
          </Radio.Button>

          <Radio.Button
            className={cx(styles.length, styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, histogram === typeHistogram.LENGTH ? styles.radioSelected : '')}
            value={typeHistogram.LENGTH}
          >
            <div id='radio_button_length'>{t('detail.length')}</div>
          </Radio.Button>

          {
            (analysisOriginal.phaseType === stockingPhaseTypes.ADULT && analysisOriginal.resultData.histogramConditionFactor && analysisOriginal.resultData.histogramConditionFactor.length > 0) &&
            <Radio.Button
              className={cx(styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, styles.conditionFactor, histogram === typeHistogram.CONDITION_FACTOR ? styles.radioSelected : '')}
              value={typeHistogram.CONDITION_FACTOR}
            >
              <div id='radio_button_condition_factor'>Factor K</div>
            </Radio.Button>
          }

          <Radio.Button
            className={cx(styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, styles.pigmentation, histogram === typeHistogram.PIGMENTATION ? styles.radioSelected : '')}
            value={typeHistogram.PIGMENTATION}
          >
            <div id='radio_button_pigmentation'>{t('detail.pigmentation')}</div>
          </Radio.Button>
        </Radio.Group>
      </div>
    );
  }

  function renderBins () {
    if (!showOptions || histogram === typeHistogram.PIGMENTATION || histogram === typeHistogram.CONDITION_FACTOR) {
      return null;
    }

    const disabledMin = numberBins === 6;
    const disabledMax = numberBins === 10;
    const disabledColorMin = disabledMin ? styles.disabled : '';
    const disabledColorMax = disabledMax ? styles.disabled : '';
    const idBinSubtract = disabledMin ? 'bin_disabled_subtract' : 'bin_subtract';
    const idBinAdd = disabledMax ? 'bin_disabled_add' : 'bin_add';

    return (
      <div className={styles.containerBins}>
        <Radio.Group className={styles.bins} size='small'>
          <Radio.Button className={cx(styles.radioButton, styles.remove, disabledColorMin, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark)} onClick={removeBin}>
            <Icon id={idBinSubtract} name='subtract' theme={theme} />
          </Radio.Button>
          <Radio.Button className={cx(styles.radioButton, styles.add, disabledColorMax, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark)} onClick={addBin}>
            <Icon id={idBinAdd} name='add' theme={theme} />
          </Radio.Button>
        </Radio.Group>
      </div>
    );
  }

  return (
    <div id='histogram_chart' className={containerClassName}>
      {renderOptions()}
      {renderBins()}
      {showAnimalsAboveBelowAverage() && <div ref={refLinea} />}

      <div ref={refChart} />
    </div>
  );
}
