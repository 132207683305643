import * as jwt from 'jsonwebtoken';

import { PayloadToken } from '../common/interfaces/auth';
import { GenericParam } from '../common/interfaces/commons';

export const environments = {
  DEV: 'dev',
  STG: 'stg',
  PROD: 'prod'
};

export const tankStatuses = {
  AVAILABLE: 'AVAILABLE',
  SOWN: 'SOWN'
};

export const roles = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  PARTIAL_ADMIN: 'PARTIAL_ADMIN',
  TECHNICAL_SUPPORT: 'TECHNICAL_SUPPORT',
  SALES_MANAGER: 'SALES_MANAGER',
  SALES: 'SALES',
  PROMOTER: 'PROMOTER',
  BUSINESS_MANAGER: 'BUSINESS_MANAGER',
  CLIENT_OWNER: 'CLIENT_OWNER',
  CLIENT_MANAGER: 'CLIENT_MANAGER',
  CLIENT_OPERATOR: 'CLIENT_OPERATOR',
  FINANCE: 'FINANCE',
  TRADING: 'TRADING',
};

export function getHigherRole (userRoles: Array<string>) {
  const rolesScore = {
    [roles.SUPER_ADMIN]: 12,
    [roles.PARTIAL_ADMIN]: 11,
    [roles.TECHNICAL_SUPPORT]: 10,
    [roles.SALES_MANAGER]: 9,
    [roles.SALES]: 8,
    [roles.PROMOTER]: 7,
    [roles.BUSINESS_MANAGER]: 6,
    [roles.CLIENT_OWNER]: 5,
    [roles.CLIENT_MANAGER]: 4,
    [roles.CLIENT_OPERATOR]: 3,
    [roles.FINANCE]: 2,
    [roles.TRADING]: 1,
  };
  const scores = userRoles.map((r) => rolesScore[r]);
  const higherRoleScore = Math.max(...scores);
  const higherRoleIndex = Object.values(rolesScore).indexOf(higherRoleScore);
  return Object.keys(rolesScore)[higherRoleIndex];
}

function getRoles () {
  const accessToken = localStorage.getItem('accessToken');
  const payloadToken = jwt.decode(accessToken || '') as PayloadToken;
  return payloadToken ? payloadToken.roles : [];
}

export function getMainRole () {
  const userRoles = getRoles();
  return getHigherRole(userRoles);
}

export function isAdminUser () {
  const higherRole = getMainRole();

  const allowedRoles = [
    roles.SUPER_ADMIN,
    roles.SALES,
    roles.SALES_MANAGER,
    roles.PROMOTER,
    roles.BUSINESS_MANAGER,
    roles.PARTIAL_ADMIN,
  ];

  return allowedRoles.includes(higherRole);
}

export function isSuperAdmin () {
  const higherRole = getMainRole();
  return higherRole === roles.SUPER_ADMIN;
}

export function hasAdminRole () {
  const higherRole = getMainRole();

  const allowedRoles = [
    roles.SUPER_ADMIN,
    roles.PARTIAL_ADMIN,
  ];

  return allowedRoles.includes(higherRole);
}

export function isFinanceRole () {
  const higherRole = getMainRole();
  return higherRole === roles.SUPER_ADMIN || higherRole === roles.SALES || higherRole === roles.FINANCE || higherRole === roles.SALES_MANAGER;
}

export function isSalesRole () {
  const userRoles: string[] = getRoles();
  return userRoles.includes(roles.SALES);
}

export function isSalesManagerRole () {
  const userRoles: string[] = getRoles();
  return userRoles.includes(roles.SALES_MANAGER);
}

export function isPromoterRole () {
  const userRoles: string[] = getRoles();
  return userRoles.includes(roles.PROMOTER);
}

export function isOwnerRole () {
  const userRoles: string[] = getRoles();
  return userRoles.includes(roles.CLIENT_OWNER);
}

export function isManagerRole () {
  const userRoles: string[] = getRoles();
  return userRoles.includes(roles.CLIENT_MANAGER);
}

export function isBusinessManagerRole () {
  const userRoles: string[] = getRoles();
  return userRoles.includes(roles.BUSINESS_MANAGER);
}

export function isTradingRole () {
  const userRoles: string[] = getRoles();
  return userRoles.includes(roles.TRADING);
}

export function isDistributorCompany () {
  return localStorage.getItem('isDistributor') === 'true';
}

export function getPayloadToken () {
  const accessToken = localStorage.getItem('accessToken');
  const payloadToken = jwt.decode(accessToken || '') as PayloadToken;
  return payloadToken;
}

export const analysisStatuses = {
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
};

export const analysisErrors = {
  NOT_A_LARVAE_SAMPLE: 'NOT_A_LARVAE_SAMPLE',
  NO_LARVAE_FOUND: 'NO_LARVAE_FOUND',
  LESS_MINIMUM_LARVAE: 'LESS_MINIMUM_LARVAE',
  BLURRED_IMAGE: 'BLURRED_IMAGE',
  DARK_IMAGE: 'DARK_IMAGE',
};

export const analysisPatch = {
  EDIT: 'EDIT',
  MOVE: 'MOVE',
  EXCLUDE_CHART: 'EXCLUDE_CHART',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  WITHOUT_STOCKING: 'WITHOUT_STOCKING',
  DESCONSOLIDATE: 'DESCONSOLIDATE',
  RELAUNCH: 'RELAUNCH',
  REMOVE_ANALYSIS: 'REMOVE_ANALYSIS',
};

export const stockingOptions = {
  EDIT: 'EDIT',
  POPULATION: 'POPULATION',
  MOVE: 'MOVE',
  FINISH: 'FINISH',
  ENABLE: 'ENABLE',
  ENABLE_TRANSFERS: 'ENABLE_TRANSFERS',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  VIEW_CURVES: 'VIEW_CURVES',
  VIEW_PARAMETER_CURVES: 'VIEW_PARAMETER_CURVES',
  ASSIGN_BINDING_CODE: 'ASSIGN_BINDING_CODE',
  FINISH_INFO: 'FINISH_INFO',
  TRANSFER_INFO: 'TRANSFER_INFO',
  HARVESTS_INFO: 'HARVESTS_INFO',
  ANALYSIS_CARD: 'ANALYSIS_CARD',
};

export const maturationOptions = {
  EDIT: 'EDIT',
  MERGE: 'MERGE',
  EDIT_INACTIVE_CODES: 'EDIT_INACTIVE_CODES',
  NEW_CODE: 'NEW_CODE',
  ACTIVATE: 'ACTIVATE',
  REMOVE: 'REMOVE',
};

export const userOptions = {
  EDIT: 'EDIT',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};

export const clientOptions = {
  EDIT: 'EDIT',
  CONFIG_MATURATIONS: 'CONFIG_MATURATIONS',
};

export const commonOptions = {
  EDIT: 'EDIT',
  REMOVE: 'REMOVE',
};

export const referenceOptions = {
  SHOW: 'SHOW',
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
};

export const stockingStatuses = {
  ACTIVE: 'ACTIVE',
  PARTIAL_HARVESTED: 'PARTIAL_HARVESTED',
  HARVESTED: 'HARVESTED',
  DISCARDED: 'DISCARDED',
  TRANSFER: 'TRANSFER',
};

export const finishStockingTabs = {
  HARVEST: 'HARVEST',
  TRANSFER: 'TRANSFER',
  DISCARDED: 'DISCARDED',
};

export const transferTypes = {
  FULL_TRANSFER: 'FULL_TRANSFER',
  PARTIAL_TRANSFER: 'PARTIAL_TRANSFER',
};

export const unitStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REMOVED: 'REMOVED',
};

export const balanceCurrentTab = {
  USERS: 'USERS',
  COMPANIES: 'COMPANIES',
};

export const stockingCurrentTab = {
  EDIT: 'EDIT',
  MOVE: 'MOVE',
  FINISH: 'FINISH',
  ENABLE: 'ENABLE',
  INVOICE: 'INVOICE',
};

export const species = {
  LITOPENAEUS_VANNAMEI: 'LITOPENAEUS_VANNAMEI'
};

export const paginationStockings = 20;
export const paginationGeneticsGlobal = 20;
export const paginationAnalysis = 20;
export const paginationHistoryManagement = 20;
export const paginationBalancesRequest = 20;
export const paginationPayments = 10;

export const analysisTags = {
  REMOVED_FROM_CHART: 'analysis.hiddenTag',
};

export const PATTERN = {
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
};

export const passwordError = {
  SAME_PASSWORD: 'samePassword',
  SAME_RECENT_PASSWORDS: 'sameRecentPasswords',
  PASSWORD_POLICY: 'passwordPolicy',
  CURRENT_PASSWORD: 'currentPassword',
  WHITE_SPACES: 'whiteSpaces'
};

export const analysisError = {
  CODE: 'code',
  LENGTH: 'length',
  IS_ARCHIVED: 'archivedAnalysis',
};

export const emailError = {
  DUPLICATE: 'emailDuplicate'
};

export const shareAnalysisError = {
  NOT_SHARED: 'notShared',
  SHARE_EXPIRED: 'shareExpired',
  PIN_REQUIRED: 'pinRequired',
  INVALID_PIN: 'invalidPin',
  NOT_AVAILABLE: 'notAvailable'
};

export const typeFetchCampus = {
  FILTER: 'FILTER',
  FORM: 'FORM',
  MOVE: 'MOVE'
};

export const typeFetchModule = {
  FILTER: 'FILTER',
  FORM: 'FORM',
  MOVE: 'MOVE'
};

export const typeFetchTank = {
  FILTER: 'FILTER',
  FORM: 'FORM',
  MOVE: 'MOVE'
};

export const inactiveCampus = 'inactiveCampus';
export const isConsolidatedAnalysis = 'isConsolidatedAnalysis';
export const analysisBelongsToConsolidated = 'analysisBelongsToConsolidated';

export const typeErrorStocking = {
  STATUS_INVALID: 'statusInvalid',
  HARVEST_QUANTITY_REQUIRED: 'harvestQuantityRequired',
  PLG_INVALID: 'plgInvalid',
  AVERAGE_WEIGHT_INVALID: 'averageWeightInvalid',
  END_DATE_REQUIRED: 'endDateRequired',
  STOCKING_FINISH: 'sowingFinished',
  HARVEST_QUANTITY_INVALID: 'harvestQuantityInvalid',
  END_DATE_INVALID: 'endDateInvalid',
  START_DATE_INVALID: 'startDateInvalid',
  START_DATE_JUVENILE_INVALID: 'startDateJuvenileInvalid',
  START_DATE_GREATER_TO_END_DATE: 'startDateGreaterToEndDate',
  STOCKING_IS_PUBLISHED: 'stockingIsPublished',
  ORIGIN_STOCKING_IS_PUBLISHED: 'currentStockingIsPublished',
  DESTINY_STOCKING_IS_PUBLISHED: 'newStockingIsPublished',
  PHASE_TYPE_INVALID: 'stockingPhaseTypeToBindingInvalid',
  MAX_DATE_TO_BINDING_CODE_INVALID: 'stockingDateToBindingInvalid',
  STOCKING_NOT_FOUND: 'stockingNotFound',
  BINDING_CODE_NOT_FOUND: 'bindingCodeNotFound',
  ALREADY_HAS_BINDING_CODE: 'stockingAlreadyHasBindingCode',
  MUST_BE_FINISHED: 'stockingMustBeFinished',
  ARCHIVED: 'stockingArchived',
  UNARCHIVED: 'stockingUnarchived',
  ACTIVE: 'sowingActive',
  INVALID_PHASE_TYPE_TO_BINDING: 'invalidPhaseTypeToBindingStockings',
  NUMBER_STOCKINGS_TO_BINDING: 'errorNumberOfStockingsToBinding',
  NUMBER_STOCKINGS_TO_TRANSFER: 'errorNumberOfStockingsToTransfer',
  STOCKINGS_MUST_BE_FINISHED: 'stockingsMustBeFinished',
  DAYS_BETWEEN_STOCKINGS: 'errorDaysBetweenStockings',
  REPEATED_CODES: 'repeatedCodes',
  STOCKING_ARE_ALREADY_BINDING: 'stockingAreAlreadyBinding',
  TANK_SOWN: 'tankSown',
  INCORRECT_ORIGIN_STOCKING_DATE: 'incorrectOriginStockingDate',
};

export const typeErrorMaturation = {
  MATURATION_NAME: 'maturationName',
  DUPLICATE_MATURATION_CODES: 'duplicateMaturationCodes',
  VALIDATION_ERROR: 'ValidationError',
};

export const analysisCategories = {
  REGULAR: 'REGULAR',
  IRREGULAR: 'IRREGULAR',
  OUTLIER: 'OUTLIER',
  OUT_STAGE: 'OUT_STAGE'
};

export const larviaDomain = 'https://larvia.ai';

export const stockingCodeLength = 4;
export const stockingBindingCodeLength = 4;

export const logBalanceOrigins = {
  ALL: 'ALL',
  MONTHLY_RELOAD: 'MONTHLY_RELOAD',
  COMPANY_CREATION: 'COMPANY_CREATION',
  USER_CREATION: 'USER_CREATION',
  BALANCE_MANAGEMENT: 'BALANCE_MANAGEMENT',
  USER_INACTIVATION: 'USER_INACTIVATION',
};

export const logBalanceTypes = {
  DEBIT: 'DEBIT ',
  ACCREDITATION: 'ACCREDITATION',
};

export const typeHistogram = {
  WEIGHT: 'WEIGHT',
  LENGTH: 'LENGTH',
  PIGMENTATION: 'PIGMENTATION',
  CONDITION_FACTOR: 'CONDITION_FACTOR',
  SURVIVAL_RATE: 'SURVIVAL_RATE',
};

export const LARVIA_ID = '5e72558455e67801acb45f7f';

export const bot = {
  fullName: 'Larvia Bot',
  name: 'Larvia',
  lastName: 'Bot'
};

export const ribbonEnvironment = {
  [environments.DEV]: 'TEST',
  [environments.STG]: 'STAGING',
};

export const analysisTypes = {
  GENERAL_LARVAE_ANALYSIS: 'GENERAL_LARVAE_ANALYSIS',
  GENERAL_JUVENILE_ANALYSIS: 'GENERAL_JUVENILE_ANALYSIS',
  GENERAL_ADULT_ANALYSIS: 'GENERAL_ADULT_ANALYSIS',
  CONSOLIDATED_LARVAE_ANALYSIS: 'CONSOLIDATED_LARVAE_ANALYSIS',
  CONSOLIDATED_JUVENILE_ANALYSIS: 'CONSOLIDATED_JUVENILE_ANALYSIS',
  CONSOLIDATED_ADULT_ANALYSIS: 'CONSOLIDATED_ADULT_ANALYSIS',
};

export const stockingPhaseTypes = {
  LARVAE: 'LARVAE',
  JUVENILE: 'JUVENILE',
  ADULT: 'ADULT',
};

export const unitPhaseTypes = {
  LARVAE: 'LARVAE',
  PRODUCTION: 'PRODUCTION',
};

export const stockingsShow = {
  ACTIVE: 'ACTIVE',
  OFFERED: 'OFFERED',
  ALL: 'ALL',
};

export const stockingTypes = {
  TRANSFER: 'TRANSFER',
  DIRECT_STOCKING: 'DIRECT_STOCKING',
};

export const containerTypes = {
  TANK: 'TANK',
  RACEWAY: 'RACEWAY',
  PRE_HATCHERY: 'PRE_HATCHERY',
  POOL: 'POOL',
};

export const CONTAINER_LABEL = {
  [stockingPhaseTypes.LARVAE]: containerTypes.TANK,
  [stockingPhaseTypes.JUVENILE]: containerTypes.RACEWAY,
  [stockingPhaseTypes.ADULT]: containerTypes.POOL,
};

export const getKeyValue = function <T extends object, U extends keyof T> (obj: T, key: U) { return obj[key]; };

export const maxNumberOfCampusToShow = 15;

export const weightUnits = {
  G: 'g',
  MG: 'mg'
};

export const lengthUnits = {
  CM: 'cm',
  MM: 'mm'
};

export const animalDaysUnit = {
  PL: 'PL',
  D: 'D'
};

export const setImageInvoiceKey = (tankId: string, userId: string, stockingCode: string) => {
  const extension = 'jpg';
  return `${userId}_${tankId}_${Date.now()}_${stockingCode}.${extension}`;
};

export const convertDataURLtoFile = (dataurl: GenericParam | string, filename: string) => {
  const dataArray = dataurl.split(',');
  const mime = (dataArray[0]?.match(/:(.*?);/) ?? [])[1] ?? '';
  const baseStr = atob(dataArray[1]);
  let nElements = baseStr.length;
  const u8arr = new Uint8Array(nElements);

  while (nElements--) {
    u8arr[nElements] = baseStr.charCodeAt(nElements);
  }
  return new File([u8arr], filename, { type: mime });
};

export const roundWeight = (params: { value: number | number[]; showUnit?: boolean; average?: number; forceCast?: boolean; decimalPlaces?: number }) => {
  const { value, showUnit = true, average = 0, forceCast = false, decimalPlaces = 2 } = params;

  const minValue = 100;
  const constantToConvert = 1000;
  const isArray = Array.isArray(value);

  if (isArray) {
    const arrayValue = value;
    if (average < minValue) {
      return arrayValue;
    }

    if (decimalPlaces === 3) {
      return arrayValue.map((weight: number) => roundThreeDecimals(weight / constantToConvert));
    }

    return arrayValue.map((weight: number) => roundTwoDecimals(weight / constantToConvert));
  }

  const numberValue = value;
  if (value >= minValue || forceCast) {
    let number = roundTwoDecimals(numberValue / constantToConvert);

    if (decimalPlaces === 3) {
      number = roundThreeDecimals(numberValue / constantToConvert);
      return showUnit ? `${number} ${weightUnits.G}` : number;
    }

    return showUnit ? `${number} ${weightUnits.G}` : number;
  }

  if (decimalPlaces === 3) {
    return showUnit ? `${roundThreeDecimals(numberValue)} ${weightUnits.MG}` : roundThreeDecimals(numberValue);
  }

  return showUnit ? `${roundTwoDecimals(numberValue)} ${weightUnits.MG}` : roundTwoDecimals(numberValue);
};

export const getWeightUnit = (average: number) => {
  if (average === 0) {
    return '';
  }

  const minValue = 100;

  if (average < minValue) {
    return weightUnits.MG;
  }

  return weightUnits.G;
};

export const roundLength = (params: { value: number | number[]; showUnit?: boolean; average?: number; forceCast?: boolean; }) => {
  const { value, showUnit = true, average = 0, forceCast = false } = params;
  const minValue = 10;
  const constantToConvert = 10;
  const isArray = Array.isArray(value);

  if (isArray) {
    const arrayValue = value;
    if (average < minValue) {
      return arrayValue;
    }

    return arrayValue.map((length: number) => roundTwoDecimals(length / constantToConvert));
  }

  const numberValue = value;
  if (value >= minValue || forceCast) {
    const number = roundTwoDecimals(numberValue / constantToConvert);
    return showUnit ? `${number} ${lengthUnits.CM}` : number;
  }

  return showUnit ? `${roundTwoDecimals(numberValue)} ${lengthUnits.MM}` : roundTwoDecimals(numberValue);
};

export const getLengthUnit = (average: number) => {
  const minValue = 10;

  if (average < minValue) {
    return lengthUnits.MM;
  }

  return lengthUnits.CM;
};

export const roundOneDecimal = (value: number) => {
  return Math.round((value + Number.EPSILON) * 10) / 10;
};

export const roundTwoDecimals = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const roundThreeDecimals = (value: number) => {
  return Math.round((value + Number.EPSILON) * 1000) / 1000;
};

export const roundFourDecimals = (value: number) => {
  return Math.round((value + Number.EPSILON) * 10000) / 10000;
};

export const xlsxReportTypes = {
  ANALYSIS_DATA: 'ANALYSIS_DATA'
};

export const typeExtension = {
  XLSX: 'xlsx',
  CSV: 'csv',
};

export const typeReport = {
  ANALYSIS: 'ANALYSIS',
  STOCKINGS: 'SOWINGS',
};

export const transferStatus = {
  PENDING_REVIEW: 'PENDING_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const typeErrorRuleAws = {
  CREATE_RULE_IN_AWS: 'createRuleInAWS',
  ADD_PERMISSION_IN_AWS: 'addPermissionInAWS',
  CREATE_TARGET_IN_AWS: 'createTargetInAWS',
  REMOVE_RULE_IN_AWS: 'removeRuleInAWS'
};

export const typeErrorBalanceAvailable = {
  PREPAID_BALANCE_AVAILABLE: 'prepaidBalanceAvailable',
  POSTPAID_BALANCE_AVAILABLE: 'postpaidBalanceAvailable',
  BONUS_QUOTA_AVAILABLE: 'bonusQuotaAvailable',
};

export const plansTypes = {
  PREPAID: 'PREPAID',
  POSTPAID: 'POSTPAID',
};

export const onboardingTypes = {
  INIT_CONFIG: 'INIT_CONFIG',
  MATURATION_SECTION: 'MATURATION_SECTION',
  UNIT_SECTION: 'UNIT_SECTION',
  STOCKING_SECTION: 'STOCKING_SECTION',
  CLIENT_DASHBOARD: 'CLIENT_DASHBOARD',
  USERS_SECTION: 'USERS_SECTION',
  PREPAID_BALANCE_SECTION: 'PREPAID_BALANCE_SECTION',
  CLIENTS_SECTION: 'CLIENTS_SECTION',
  PAYPAL_SECTION: 'PAYPAL_SECTION',
  ADMINISTRATION_HISTORY: 'ADMINISTRATION_HISTORY',
};

export const typeErrorUpdateClient = {
  COMPANY_ALREADY_ASSIGNED: 'companyAlreadyAssigned',
  COMPANY_BE_ASSIGNED_TO_ITSELF: 'companyBeAssignedToItself',
  PARENT_COMPANY_BE_ASSIGNED: 'parentCompanyBeAssigned',
  INVALID_QUOTA_DISTRIBUTION_COMPANY: 'invalidQuotaDistributionCompany'
};

export const companyBalanceLimits = {
  MIN: 50,
  MAX: 15000
};

export const typeCompany = {
  NORMAL: 'NORMAL',
  DISTRIBUTOR: 'DISTRIBUTOR',
};

export const campusUpdateError = {
  ACTIVE_STOCKINGS: 'activeSowings',
};

export const IVA = 1.12;

export function applyIVA (value: number) {
  return roundTwoDecimals(value * IVA);
}

export const bucketOriginalJuvenile = 'arn:aws:s3:us-east-1:505632187603:accesspoint/prod-original-juvenile-ap';

export const typeErrorPayment = {
  PREPAID_BALANCE_NOT_AVAILABLE: 'prepaidBalanceNotAvailable',
  PREPAID_BALANCE_BEING_USED: 'prepaidBalanceBeingUsed',
  POSTPAID_BALANCE_AVAILABLE: 'postpaidBalanceAvailable',
  PREPAID_BALANCE_AVAILABLE: 'prepaidBalanceAvailable',
  BONUS_BALANCE_AVAILABLE: 'bonusQuotaAvailable',
  TRANSFER_ALREADY_REGISTERED: 'transferAlreadyRegistered',
  WRONG_COMPANY_CODES: 'wrongCompanyCodes',
};

export const bucketName = {
  INVOICE_STOCKING: 'invoice-stocking',
  PREPAID_VOUCHERS: 'prepaid-vouchers',
  POSTPAID_VOUCHERS: 'postpaid-vouchers',
};

export const methodPresignedUrl = {
  POST: 'post',
  GET: 'get',
};

export const DEFAULT_DAYS_TO_INITIAL_STAGE = 7;
export const MIN_DAYS_TO_INITIAL_STAGE = 6;
export const MAX_DAYS_TO_INITIAL_STAGE = 12;

export const DEFAULT_STAGE_MAX = 22;
export const DEFAULT_DAY_MAX_JUVENILE = 35;
export const DEFAULT_DAY_MAX_GROW_OUT = 180;

export const PROVINCES_ECUADOR = ['ESMERALDAS', 'MANABI', 'LOS RIOS', 'GUAYAS', 'EL ORO', 'SANTA ELENA'];

export const companiesTypes = {
  ALL: 'ALL',
  NATIONAL: 'NATIONAL',
  INTERNATIONAL: 'INTERNATIONAL',
  ACTIVE: 'ACTIVE',
  CLIENT: 'CLIENT',
  INACTIVE: 'INACTIVE',
  TRIAL_PHASE: 'TRIAL_PHASE',
};

export const archiveActions = {
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE'
};

export const quotaErrors = {
  minimumQuota: 'minimumQuota',
  userBalances: 'userBalances',
};

export const DATE_FORMATS = Object.freeze({
  YYYY_MM_DD: 'YYYY-MM-DD',
  DD_MM_YY: 'DD/MM/YY',
  MMM_DD_YYYY: 'MMM DD, YYYY',
  DD_MMM_YYYY: 'DD MMM, YYYY',
});

export const LANGUAGES = Object.freeze({
  ENGLISH: 'EN',
  SPANISH: 'ES',
  PORTUGUESE: 'PT',
});

export const THEME = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const maximumNumberOfStockings = 8;

export const maturationGlobalStatus = {
  PENDING_REVIEW: 'PENDING_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const editSampleError = {
  SAMPLE_WAS_EDITED: 'sampleWasEdited',
  COORDINATES_REMOVED: 'coordinatesRemoved',
  IS_EDITING_SAMPLE: 'isEditingSample',
};

export const INITIAL_DATE = '01-01-2020';

export const stockingStageOptions = Object.freeze({
  NAUPLII: 0,
  PL1: 1,
  PL2: 2,
  PL3: 3,
  PL4: 4,
  PL5: 5,
  PL6: 6,
  PL7: 7,
  PL8: 8,
  PL9: 9,
  PL10: 10,
  PL11: 11,
  PL12: 12,
  PL13: 13,
  PL14: 14,
  PL15: 15,
});

export const stockingSearchTypes = {
  NAME: 'NAME',
  CYCLE: 'CYCLE',
  LAB: 'LAB',
};

export const weightUnitsByCompany = {
  KILOGRAM_AND_POUND: 'KILOGRAM_AND_POUND',
  KILOGRAM: 'KILOGRAM',
  POUND: 'POUND',
};

export const LBS_TO_KG_RATIO = 0.45359237; //1 pound equals 0.45359237 kilograms
export const LBS_TO_GR_RATIO = LBS_TO_KG_RATIO * 1000; //1 pound equals 453.59237 grams
export const MAX_SURVIVAL_RATE = 100;
export const TICKS_DAYS_RANGE = 7;

export const MINIMUM_DATE_FACTOR_K_CHART = new Date('2024-05-14');

export const stockingParameterTypes = {
  CATEGORICAL: 'CATEGORICAL',
  NUMERIC: 'NUMERIC',
};

export const stockingParameterOptions = {
  EDIT: 'EDIT',
  SHOW: 'SHOW',
};

export const defaultStockingData = {
  TEMPERATURE: 'Temperature',
  PH: 'pH',
  OXYGEN: 'Oxygen',
  TOTAL_FEED: 'Total feed',
};

export const referenceCurveMaxValues = {
  LARVAE: {
    PLG: 3500,
    AVERAGE_WEIGHT: 1000,
    AVERAGE_LENGHT: 20,
    UNIFORMITY: 100,
  },
  JUVENILE: {
    AVERAGE_WEIGHT: 4000,
    AVERAGE_LENGHT: 80,
    UNIFORMITY: 100,
  },
  GROW_OUT: {
    AVERAGE_WEIGHT: 70000,
    AVERAGE_LENGHT: 300,
    UNIFORMITY: 100,
  }
};

export const frequencyTypes = {
  INDIVIDUAL: 'INDIVIDUAL',
  AGGREGATED: 'AGGREGATED',
};

export const aggregationMethods = {
  SUM: 'SUM',
  AVERAGE: 'AVERAGE',
};

export const stockingParameterErrors = {
  ONE_PARAMETER_BY_DATE: 'oneParameterByDate',
};

export const platform = {
  MOBILE: 'MOBILE',
  WEB: 'WEB',
};
