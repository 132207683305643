import moment from 'moment';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { Row, Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getUserSession } from '../../../utils/userSession';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { formatYearMonthDay, getHoursOffset } from '../../../utils/date';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvRadioGroup } from '../../../common/components/LrvRadioGroup/LrvRadioGroup';
import { LrvRadioButton } from '../../../common/components/LrvRadioButton/LrvRadioButton';
import { LrvCancelButton } from '../../../common/components/LrvCancelButton/LrvCancelButton';
import { typeExtension, typeReport, xlsxReportTypes, stockingPhaseTypes } from '../../../config/commons';
import { setXlsxReportFilters, setXlsxReportData, setTypeReportCsv, setTypeFile, fetchXlsxReportData, setShowXlsxReport } from '../sowingsSlice';

import './XlsxReport.scss';
import styles from './XlsxReport.module.scss';


interface XlsxData {
  mimeType: string;
  extension: string;
  data: string;
}

interface Props {
  theme?: 'dark' | 'light';
}

function XlsxReport (props: Props) {
  const { theme = 'dark' } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const isLoadingDataReport = useSelector((state: Store) => state.stockings.xlsxReport.isLoading);
  const typeFile = useSelector((state: Store) => state.stockings.xlsxReport.typeFile);
  const typeReportCsv = useSelector((state: Store) => state.stockings.xlsxReport.typeReportCsv);
  const fromDate = useSelector((state: Store) => state.stockings.xlsxReport.fromDate);
  const toDate = useSelector((state: Store) => state.stockings.xlsxReport.toDate);
  const xlsxData = useSelector((state: Store) => state.stockings.xlsxReport.data as XlsxData);

  const showXlsxAnalysisReport = useSelector((state: Store) => state.stockings.xlsxReport.showModal);
  const { phaseType: phaseTypeSelected } = useSelector((state: Store) => state.header);

  const userSession = getUserSession();

  useEffect(() => {
    if (xlsxData.data) {
      const link = document.createElement('a');
      if (xlsxData.extension === `.${typeExtension.XLSX}`) {
        link.href = `data:${xlsxData.mimeType};base64,${xlsxData.data}`;
      } else if (xlsxData.extension === `.${typeExtension.CSV}`) {
        link.href = `data:${xlsxData.mimeType};charset=utf-8,${xlsxData.data}`;
      }

      link.setAttribute('download', `${'DATA'}${xlsxData.extension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      return (() => {
        dispatch(setXlsxReportData({}));
      });
    }
  }, [dispatch, xlsxData]);

  function disabledDateFrom (current: moment.Moment) {
    const minDays = 1365;
    const start = moment().subtract(minDays, 'days');
    const end = moment(toDate);
    return !(start.isSameOrBefore(current) && end.isAfter(current));
  }

  function disabledDateTo (current: moment.Moment) {
    const start = moment(fromDate).add(1, 'days');
    const end = moment();
    return !(start.isSameOrBefore(current) && end.isAfter(current));
  }

  function renderSelectFormatIcon () {
    switch (typeFile) {
      case typeExtension.XLSX:
      default:
        return (
          <div className={styles.typeFile}>
            <Icon name='file-excel-2' className={styles.icon} />
            <div className={styles.format}>
              {t('stockings.xlsxReport.formats.xlsx').toUpperCase()}
            </div>
            <Icon name='arrow-down-s' type='fill' className={styles.icon} />
          </div>
        );

      case typeExtension.CSV:
        return (
          <div className={styles.typeFile}>
            <Icon name='file-text' className={styles.icon} />
            <div className={styles.format}>
              {t('stockings.xlsxReport.formats.csv').toUpperCase()}
            </div>
            <Icon name='arrow-down-s' type='fill' className={styles.icon} />
          </div>
        );
    }
  }

  const renderMenuFormat = () => {
    return (
      <Menu onClick={(e) => dispatch(setTypeFile(e.key))}>
        <Menu.Item key={typeExtension.XLSX}>
          <div className={styles.radioButton}>
            <Icon name='file-excel-2' theme='light' />
            &nbsp;
            {t('stockings.xlsxReport.excel')}
          </div>
        </Menu.Item>
        <Menu.Item key={typeExtension.CSV}>
          <div className={styles.radioButton}>
            <Icon name='file-text' theme='light' />
            &nbsp;
            {t('stockings.xlsxReport.csv')}
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  function renderTypeReportCsv () {
    if (typeFile !== typeExtension.CSV) {
      return null;
    }

    return (
      <div className={styles.filterTypeFile}>
        <LrvText
          theme={theme}
          className={styles.dataToDownload}
          text={t('stockings.xlsxReport.dataToDownload')}
        />
        <LrvRadioGroup
          theme={theme}
          value={typeReportCsv}
          onChange={(event) => dispatch(setTypeReportCsv(event.target.value))}
          className={styles.reportType}
        >
          <LrvRadioButton
            theme={theme}
            value={typeReport.ANALYSIS}
            className={styles.radioButtonLeft}
          >
            <div id='global_source_button'>{t('stockings.xlsxReport.analysis')}</div>
          </LrvRadioButton>

          <LrvRadioButton
            theme={theme}
            value={typeReport.STOCKINGS}
            className={styles.radioButtonRight}
          >
            <div id='company_source_button'>{t('stockings.xlsxReport.stockings')}</div>
          </LrvRadioButton>
        </LrvRadioGroup>
      </div>
    );
  }

  function getXlsxReportData () {
    const data = {
      fromDate: formatYearMonthDay(fromDate),
      toDate: formatYearMonthDay(toDate),
      companyId: userSession.companyId,
      type: xlsxReportTypes.ANALYSIS_DATA,
      phaseType: phaseTypeSelected,
      typeFile: typeFile,
      typeReportCsv: typeReportCsv,
      userLang: userSession.language,
      hoursOffset: getHoursOffset(),
    };

    dispatch(fetchXlsxReportData(data));
  }

  const getTitle = () => {
    switch (phaseTypeSelected) {
      case stockingPhaseTypes.LARVAE:
      default:
        return `${t('stockings.xlsxReport.title')} (${t('stockings.xlsxReport.phases.larvae')})`;

      case stockingPhaseTypes.JUVENILE:
        return `${t('stockings.xlsxReport.title')} (${t('stockings.xlsxReport.phases.juvenile')})`;

      case stockingPhaseTypes.ADULT:
        return `${t('stockings.xlsxReport.title')} (${t('stockings.xlsxReport.phases.growOut')})`;
    }
  };

  return (
    <LrvModal
      theme={theme}
      className='xlsxReportModal'
      title={getTitle()}
      open={showXlsxAnalysisReport}
      destroyOnClose={true}
      onCancel={() => dispatch(setShowXlsxReport(false))}
      footer={null}
      cancelText={t('stockings.cancel')}
    >
      <div className={styles.containerFull}>
        <Row gutter={20} className={styles.row}>
          <div className={styles.description}>
            <div>
              <LrvText className={styles.text} text={`* ${t('stockings.xlsxReport.description.analysis')}`} theme={theme} />
            </div>
            <div>
              <LrvText className={styles.text} text={`* ${t('stockings.xlsxReport.description.stocking')}`} theme={theme} />
            </div>
          </div>
          <div className={styles.filterDate}>
            <LrvDatePicker
              id='from_date_picker'
              theme={theme}
              className={styles.datePicker}
              title={t('stockings.xlsxReport.dateFrom')}
              value={moment(fromDate)}
              defaultValue={moment(fromDate)}
              allowClear={false}
              disabledDate={disabledDateFrom}
              placeholder={t('stockings.xlsxReport.dateFrom')}
              onChange={(date, dateString) => {
                const filters = { fromDate: dateString, toDate };
                dispatch(setXlsxReportFilters(filters));
              }}
            />

            <LrvDatePicker
              id='to_date_picker'
              theme={theme}
              className={styles.datePicker}
              title={t('stockings.xlsxReport.dateTo')}
              value={moment(toDate)}
              defaultValue={moment(toDate)}
              allowClear={false}
              disabledDate={disabledDateTo}
              placeholder={t('stockings.xlsxReport.dateTo')}
              onChange={(date, dateString) => {
                const filters = {
                  fromDate,
                  toDate: dateString
                };
                dispatch(setXlsxReportFilters(filters));
              }}
            />


            {renderTypeReportCsv()}
          </div>
        </Row>

        <Row gutter={20} className={styles.rowButtons}>
          <LrvCancelButton
            theme={theme}
            type='text'
            onClick={() => dispatch(setShowXlsxReport(false))}
          >
            {t('stockings.cancel').toUpperCase()}
          </LrvCancelButton>

          <Dropdown.Button
            type='primary'
            loading={isLoadingDataReport}
            disabled={(isLoadingDataReport || (!fromDate) || (!toDate))}
            className={cx(styles.dropdown, 'dropdown')}
            overlay={renderMenuFormat}
            placement='bottom'
            icon={renderSelectFormatIcon()}
            onClick={getXlsxReportData}
          >
            <>
              <Icon name='download' className={styles.downloadIcon} />
              {t('stockings.xlsxReport.download').toUpperCase()}
            </>
          </Dropdown.Button>
        </Row>
      </div>
    </LrvModal>
  );
}

export default XlsxReport;
